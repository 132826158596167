export const heightMapCombineShader = `
varying vec2 vUv;
uniform sampler2D texture0; // varnish
uniform sampler2D texture1; // silver
uniform sampler2D texture2; // gold
uniform vec4 vSizeTexture;

void main() {
vec2	vUv = vUv; // + 0.5 * vSizeTexture.zw;
vec4	cVarnish = texture2D( texture0, vUv.xy );
float	fVarnish = 0.33333 * ( cVarnish.r + cVarnish.g + cVarnish.b );
fVarnish = step(0.1, cVarnish.a) * step( 0.0, fVarnish ) * max( 0.1, 1.0 - fVarnish );

vec4	cSilver = texture2D( texture1, vUv.xy );
float	fSilver = 0.33333 * ( cSilver.r + cSilver.g + cSilver.b );
fSilver = step(0.1, cSilver.a) * step( 0.0, fSilver ) * 0.1;

vec4	cGold = texture2D( texture2, vUv.xy );
float	fGold = 0.33333 * ( cGold.r + cGold.g + cGold.b );
fGold = step(0.1, cGold.a) * step( 0.0, fGold ) * 0.1;
float fLuminance = fVarnish + fSilver + fGold;

 gl_FragColor = vec4( fLuminance, fLuminance, fLuminance, 1.0 ) ;
}
`;
