import React, { forwardRef } from "react";
import { Group, Texture } from "three";
import { Coords3D, FoldingTree, SmartLabelIntersection } from "../Domain";
import { Annotations } from "../Domain/Annotations";
import { FoilMapping } from "../Domain/Foil";
import { Annotation3D } from "./Annotation3D";
import DesignFace from "./designFace";
import { VarnishMaterialProps } from "./Layers/Varnish";
import { LedDisplayProps } from "./Leds";
import { PackagingTextureCache } from "./PackagingTextureCache";
import { CreationState, PaperMaterial } from "./PreviewScene";
import { RotationBetweenFaces } from "./RotationBetweenFaces";
import { SmartLabelIntersection3D } from "./SmartLabelIntersection3D";

type PackagingFace3dProps = {
  node: FoldingTree;
  creationState: CreationState;
  origin: Coords3D;
  enableAxesHelper: boolean;
  rotationFactor: number;
  opacity: number;
  ledProps: LedDisplayProps;
  smartLabelIntersections: SmartLabelIntersection[];
  folded: boolean;
  textureCache?: PackagingTextureCache;
  onNewAnnotation?: React.Dispatch<Annotations>;
  cancelNewAnnotation?: React.Dispatch<void>;
  envMap?: Texture;
  varnishMaterialProps: VarnishMaterialProps;
  displayedMap: string;
  spriteMaterialMode: boolean;
  annotationSize: number;
  activePaperName: PaperMaterial;
  annotationMode: "display" | "pendingPlacement" | "pendingValidation";
  handleDeleteEvent?: React.Dispatch<Annotations>;
  onSelect3DAnnotation?: React.Dispatch<Annotations>;
  foilMapping: FoilMapping;
  foilDiffuseColor: string;
};
export const PackagingFace3D = forwardRef<Group, PackagingFace3dProps>(
  (
    {
      node,
      creationState,
      origin,
      enableAxesHelper,
      rotationFactor,
      opacity,
      ledProps,
      smartLabelIntersections,
      folded,
      textureCache,
      onNewAnnotation,
      cancelNewAnnotation,
      envMap,
      varnishMaterialProps,
      displayedMap,
      spriteMaterialMode,
      annotationSize,
      activePaperName,
      annotationMode,
      handleDeleteEvent,
      onSelect3DAnnotation,
      foilMapping,
      foilDiffuseColor,
    }: PackagingFace3dProps,
    ref
  ) => {
    const { packaging } = creationState;
    const faceGeometry = packaging.dieline.faceGeometries[node.faceName];

    const absolutePosition: Coords3D = [faceGeometry.x, -faceGeometry.y, 0];
    const relativePosition: Coords3D = [
      absolutePosition[0] - origin[0],
      absolutePosition[1] - origin[1],
      absolutePosition[2] - origin[2],
    ];

    // Use global layer textures
    return (
      <>
        <group position={relativePosition} ref={ref}>
          {enableAxesHelper && <axesHelper args={[faceGeometry.width / 5]} />}
          {
            <DesignFace
              node={node}
              creationState={creationState}
              opacity={opacity}
              textureCache={textureCache}
              onNewAnnotation={onNewAnnotation}
              envMap={envMap}
              varnishMaterialProps={varnishMaterialProps}
              displayedMap={displayedMap}
              annotationSize={annotationSize}
              activePaperName={activePaperName}
              packaging={packaging}
              faceGeometry={faceGeometry}
              verso={false}
              foilMapping={foilMapping}
              foilDiffuseColor={foilDiffuseColor}
            />
          }
          {creationState.packaging.design?.verso && (
            <DesignFace
              node={node}
              creationState={creationState}
              opacity={opacity}
              textureCache={textureCache}
              onNewAnnotation={onNewAnnotation}
              envMap={envMap}
              varnishMaterialProps={varnishMaterialProps}
              displayedMap={displayedMap}
              annotationSize={annotationSize}
              activePaperName={activePaperName}
              packaging={packaging}
              faceGeometry={faceGeometry}
              verso
              foilMapping={foilMapping}
              foilDiffuseColor={foilDiffuseColor}
            />
          )}
          {smartLabelIntersections
            .filter((intersection) => intersection.faceName === node.faceName)
            .map((intersection) => (
              <SmartLabelIntersection3D
                key={intersection.smartLabel.key}
                intersection={intersection}
                enableAxesHelper={enableAxesHelper}
                absolutePosition={absolutePosition}
                ledProps={ledProps}
              />
            ))}
          {node.children?.map((node) => {
            return (
              <RotationBetweenFaces
                key={node.faceName}
                folded={folded}
                node={node}
                absolutePosition={absolutePosition}
                enableAxesHelper={enableAxesHelper}
                rotationFactor={rotationFactor}
              >
                <PackagingFace3D
                  node={node}
                  creationState={creationState}
                  origin={node.rotation.origin}
                  enableAxesHelper={enableAxesHelper}
                  rotationFactor={rotationFactor}
                  opacity={opacity}
                  ledProps={ledProps}
                  smartLabelIntersections={smartLabelIntersections}
                  folded={folded}
                  textureCache={textureCache}
                  onNewAnnotation={onNewAnnotation}
                  cancelNewAnnotation={cancelNewAnnotation}
                  envMap={envMap}
                  varnishMaterialProps={varnishMaterialProps}
                  displayedMap={displayedMap}
                  spriteMaterialMode={spriteMaterialMode}
                  annotationSize={annotationSize}
                  activePaperName={activePaperName}
                  annotationMode={annotationMode}
                  handleDeleteEvent={handleDeleteEvent}
                  onSelect3DAnnotation={onSelect3DAnnotation}
                  foilMapping={foilMapping}
                  foilDiffuseColor={foilDiffuseColor}
                />
              </RotationBetweenFaces>
            );
          })}

          {creationState.annotations.map((annotation: Annotations) => (
            <Annotation3D
              selectedAnnotation={creationState.selectedAnnotation}
              annotation={annotation}
              faceName={node.faceName}
              spriteMaterialMode={spriteMaterialMode}
              annotationSize={annotationSize}
              cancelNewAnnotation={cancelNewAnnotation}
              annotationMode={annotationMode}
              handleDeleteEvent={handleDeleteEvent}
              onSelect3DAnnotation={onSelect3DAnnotation}
            />
          ))}
        </group>
      </>
    );
  }
);
