export const blurShader = `

uniform sampler2D texture0;
uniform	vec4	vSizeTexture;
varying vec2 vUv;

void main() {
vec2 vTex = vUv;// + 0.5 * vSizeTexture.zw;
vec4 cColor = texture2D( texture0, vTex.xy );
//gl_FragColor =  cColor;
cColor += texture2D( texture0, vTex.xy + vec2( -0.5 * vSizeTexture.z, -1.5 * vSizeTexture.w ) );
cColor += texture2D( texture0, vTex.xy + vec2( 1.5 * vSizeTexture.z, -0.5 * vSizeTexture.w ) );
cColor += texture2D( texture0, vTex.xy + vec2( 0.5 * vSizeTexture.z, 1.5 * vSizeTexture.w ) );
cColor += texture2D( texture0, vTex.xy + vec2( -1.5 * vSizeTexture.z, 0.5 * vSizeTexture.w ) );
gl_FragColor = 0.2 * cColor;
}
`;
