import React, { ReactNode, useRef } from "react";
import { animated, useSpring } from "react-spring";
import { Group } from "three";
import { Coords3D, FoldingTree } from "../Domain";

export function RotationBetweenFaces({
  children,
  folded,
  node,
  absolutePosition,
  enableAxesHelper,
  rotationFactor,
}: {
  children?: ReactNode;
  folded: boolean;
  node: FoldingTree;
  absolutePosition: Coords3D;
  enableAxesHelper: boolean;
  rotationFactor: number;
}) {
  const absoluteRotationPosition: Coords3D = node.rotation.origin ?? [0, 0, 0];
  const relativeRotationPosition: Coords3D = [
    absoluteRotationPosition[0] - absolutePosition[0],
    absoluteRotationPosition[1] - absolutePosition[1],
    absoluteRotationPosition[2] - absolutePosition[2],
  ];
  const rotationAngle = node.rotation.prop.map(x => x * rotationFactor);
  const { rotation } = useSpring<{ rotation: [number, number, number] }>({
    rotation: folded ? rotationAngle : [0, 0, 0],
    config: { tension: 100 },
  });
  const groupRef = useRef<Group>(null!);
  return (
    <animated.group
      ref={groupRef}
      key={node.faceName}
      name="rotationPoint"
      position={relativeRotationPosition}
      rotation={rotation}
    >
      {enableAxesHelper && <axesHelper args={[1000]} />}
      {children}
    </animated.group>
  );
}
