import React, { useMemo } from "react";
import { DoubleSide, Mesh, SpriteMaterial, TextureLoader } from "three";
import { Annotations } from "../Domain/Annotations";

type AnnotationsProps = {
  annotation: Annotations;
  faceName: string;
  positionPointRef?: React.MutableRefObject<Mesh | undefined>;
  spriteMaterialMode: boolean;
  annotationSize: number;
  selectedAnnotation: Annotations | undefined;
  cancelNewAnnotation?: React.Dispatch<void>;
  annotationMode: "display" | "pendingPlacement" | "pendingValidation";
  handleDeleteEvent?: React.Dispatch<Annotations>;
  onSelect3DAnnotation?: React.Dispatch<Annotations>;
};
export function Annotation3D({
  annotation,
  faceName,
  positionPointRef,
  spriteMaterialMode,
  annotationSize,
  handleDeleteEvent,
  onSelect3DAnnotation,
}: AnnotationsProps) {
  const loader = useMemo(() => new TextureLoader(), []);
  const targetTexture = useMemo(
    () => loader.load(`${process.env.PUBLIC_URL}/cible.png`),
    []
  );

  const targetTextureAlpha = useMemo(
    () => loader.load(`${process.env.PUBLIC_URL}/cibleAlpha.png`),
    []
  );

  const close3DImg = useMemo(
    () => loader.load(`${process.env.PUBLIC_URL}/deleteannotation3D.png`),
    []
  );

  const spriteMaterial = new SpriteMaterial({
    map: targetTexture,
    alphaMap: targetTextureAlpha,
    transparent: true,
    alphaTest: 0.5,
    depthTest: false,
  });

  return (
    <>
      {annotation.face === faceName && (
        <>
          {spriteMaterialMode ? (
            <sprite
              name={faceName}
              position={[annotation.x, annotation.y, annotation.z]}
              scale={[200, 200, 0]}
              ref={positionPointRef}
              renderOrder={1000}
              material={spriteMaterial}
            />
          ) : (
            <>
              {annotation.isSelected && (
                <>
                  <mesh
                    name={faceName}
                    position={[
                      annotation.x + annotationSize,
                      annotation.y + annotationSize,
                      annotation.z + 1.2,
                    ]}
                    ref={positionPointRef}
                    renderOrder={1000}
                    onClick={() => {
                      handleDeleteEvent && handleDeleteEvent(annotation);
                    }}
                  >
                    <planeBufferGeometry
                      attach="geometry"
                      args={[0.5 * annotationSize, 0.5 * annotationSize, 0]}
                    />

                    <meshBasicMaterial
                      attach="material"
                      transparent
                      map={close3DImg}
                      side={DoubleSide}
                    />
                  </mesh>
                  <mesh
                    name={faceName}
                    position={[annotation.x, annotation.y, annotation.z + 1.2]}
                    ref={positionPointRef}
                    renderOrder={1000}
                  >
                    <boxBufferGeometry attach="geometry" args={[10, 10, 10]} />

                    <ringBufferGeometry
                      attach="geometry"
                      args={[0.95 * annotationSize, annotationSize, 36]}
                    />

                    <meshBasicMaterial
                      attach="material"
                      color="#5596FF"
                      transparent
                      side={DoubleSide}
                    />
                  </mesh>
                </>
              )}
              <mesh
                name={faceName}
                position={[annotation.x, annotation.y, annotation.z + 1.2]}
                ref={positionPointRef}
                renderOrder={1000}
                onClick={() => {
                  annotation &&
                    !annotation.isSelected &&
                    onSelect3DAnnotation &&
                    onSelect3DAnnotation(annotation);
                }}
              >
                <ringBufferGeometry
                  attach="geometry"
                  args={[0.6 * annotationSize, 0.8 * annotationSize, 32]}
                />
                <meshBasicMaterial
                  attach="material"
                  color="#0000ff"
                  transparent
                  side={DoubleSide}
                  opacity={annotation.isSelected === false ? 0.25 : 1}
                />
              </mesh>
            </>
          )}
        </>
      )}
    </>
  );
}
