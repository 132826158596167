export type LayerView = {
  name: string;
  enable: boolean;
};

/**
 * Tests if a layer is enabled in an array of layerViews
 * @param name
 * @param layerViews
 * @returns true if layer is enabled or missing from views, false otherwise
 *
 */
export function layerIsEnabled(name: string, layerViews: LayerView[]): boolean {
  const layerView: LayerView | undefined = layerViews.find(
    (layer) => layer.name === name
  );
  return layerView ? layerView.enable : true;
}
