import { OrthographicCamera, Texture, WebGLRenderer } from "three";
import { initRenderTarget } from "./initRenderTarget";

export function initRenderTargetTexture(
  gl: WebGLRenderer,
  textures: Texture[] | undefined,
  fragmentShader: string
) {
  const [rtTexture, quadScene] = textures
    ? initRenderTarget(textures, fragmentShader)
    : [undefined, undefined];

  if (rtTexture && textures && quadScene) {
    const image = textures[0]?.image as HTMLImageElement;

    rtTexture.texture.offset = textures[0].offset;
    rtTexture.texture.repeat = textures[0].repeat;

    const cameraRTT = new OrthographicCamera(
      -image.width / 2,
      image.width / 2,
      image.height / 2,
      -image.height / 2,
      -10000,
      10000
    );
    gl.setRenderTarget(rtTexture);
    gl.clear();
    gl.render(quadScene, cameraRTT);

    gl.setRenderTarget(null);
  }

  return rtTexture;
}
