import React, { useEffect, useMemo, useRef } from "react";
import { animated, SpringValue } from "react-spring";
import {
  Color,
  Material,
  PlaneBufferGeometry,
  ShaderMaterial,
  Texture,
  Vector2,
  Vector4,
} from "three";
import { FaceGeometry, layerIsEnabled, LayerView } from "../../Domain";
import { FoilMaterial } from "../../Domain/Foil";
import { useWhiteTexture } from "../../hooks/WhiteTexture";
import { fragmentShaderNormal } from "../Shaders/fragmentShaderNormal";
import { vertexShader } from "../Shaders/vertexShader";

type FoilProps = {
  foilNormalNoise?: Texture;
  shaderMaterialMode: Boolean;
  geometry: PlaneBufferGeometry;
  faceGeometry: FaceGeometry;
  normalMap: Texture | undefined;
  foil: Texture | undefined;
  animatedOpacity: SpringValue<number>;
  faceName: string;
  envMap?: Texture;
  parallaxScale: number;
  parallaxMinLayers: number;
  parallaxMaxLayers: number;
  faceUVs: { repeat: Vector2; offset: Vector2 };
  verso?: boolean;
  mask?: Texture;

  layersView: LayerView[];
  layerName: string;
  foilMaterial: FoilMaterial;
  foilDiffuseColor: string;
};

const Foil = ({
  foilNormalNoise,
  shaderMaterialMode,
  geometry,
  faceGeometry,
  normalMap,
  foil,
  animatedOpacity,
  faceName,
  envMap,
  parallaxScale,
  parallaxMinLayers,
  parallaxMaxLayers,
  faceUVs,
  verso,
  mask,

  layersView,
  layerName,
  foilMaterial,
  foilDiffuseColor,
}: FoilProps) => {
  const materialRef = useRef<ShaderMaterial>();

  const white = useWhiteTexture();
  
  const foilUniformsRecto = useMemo(() => {
    return {
      diffuse: { value: foilMaterial.diffuse  },
      opacity: { value: animatedOpacity },
      map: { value: foil },
      uvTransform: {
        value: [
          faceUVs.repeat.x,
          -0,
          0,
          0,
          faceUVs.repeat.y,
          0,
          faceUVs.offset.x,
          faceUVs.offset.y,
          1,
        ],
      },
      uv2Transform: { value: [1, 0, 0, 0, 1, 0, 0, 0, 1] },
      alphaMap: { value: mask ?? white },
      envMap: { value: envMap },
      flipEnvMap: { value: -1 },
      reflectivity: { value: foilMaterial.reflectivity },
      refractionRatio: { value: 0.98 },
      maxMipLevel: { value: 0 },
      aoMap: { value: null },
      aoMapIntensity: { value: 1 },
      lightMap: { value: null },
      lightMapIntensity: { value: 1 },
      emissiveMap: { value: foil },
      normalMap: { value: normalMap },
      normalScale: { value: { x: 1, y: 1 } },
      roughnessMap: { value: null },
      metalnessMap: { value: null },
      ambientLightColor: {
        value: [0.7278431372549019, 0.7278431372549019, 0.7278431372549019],
        needsUpdate: true,
      },
      directionalLights: {
        value: [
          {
            direction: {
              x: -0.6569329722189172,
              y: -0.17460088517151215,
              z: 0.7334532029440893,
            },
            color: new Color(0.5, 0.5, 0.5),
          },
          {
            direction: {
              x: 0.7478311872357977,
              y: 0.14336756698608208,
              z: -0.6482239243764369,
            },
            color: new Color(0, 0, 0),
          },
        ],
        needsUpdate: true,
      },
      emissive: {
        value: foilMaterial.emissive,
      },
      roughness: { value: foilMaterial.roughness },
      metalness: { value: foilMaterial.metalness },
      envMapIntensity: { value: foilMaterial.envMapIntensity },
      transparency: { value: 1 },
      clearcoat: { value: 0 },
      clearcoatRoughness: { value: 0 },
      sheen: { value: new Color(0, 0, 0) },
      clearcoatNormalScale: { value: { x: 1, y: 1 } },
      clearcoatNormalMap: { value: null },
      clippingPlanes: { value: null, needsUpdate: false },
      parallaxScale: { value: parallaxScale },
      parallaxMinLayers: { value: parallaxMinLayers },
      parallaxMaxLayers: { value: parallaxMaxLayers },
      normalNoise: { value: foilNormalNoise },
      normalScaleFactor: { value: foilMaterial.normalScaleFactor },
      vecXNormalScale: { value: foilMaterial.vecXNormalScale },
      vecYNormalScale: { value: foilMaterial.vecYNormalScale },
      flip: { value: new Vector4(0, 1, 0, 1) },
    };
  }, []);

  useEffect(() => {
    if (materialRef.current) {
      materialRef.current.uniformsNeedUpdate = true;
      materialRef.current.uniforms["diffuse"].value = foilMaterial.diffuse;
      materialRef.current.uniforms["emissive"].value = foilMaterial.emissive;
      materialRef.current.uniforms["roughness"].value = foilMaterial.roughness;
      materialRef.current.uniforms["metalness"].value = foilMaterial.metalness;
      materialRef.current.uniforms["reflectivity"].value =
        foilMaterial.reflectivity;
      materialRef.current.uniforms["envMapIntensity"].value =
        foilMaterial.envMapIntensity;
      materialRef.current.uniforms["normalScaleFactor"].value =
        foilMaterial.normalScaleFactor;
      materialRef.current.uniforms["vecXNormalScale"].value =
        foilMaterial.vecXNormalScale;
      materialRef.current.uniforms["vecYNormalScale"].value =
        foilMaterial.vecYNormalScale;
    }
  }, [foilMaterial, foilDiffuseColor]);

  const foilUniformsVerso = {
    ...foilUniformsRecto,
    flip: { value: new Vector4(1, -1, 0, 1) },
  };

  return (
    <mesh
      name={faceName}
      geometry={geometry}
      position={[
        faceGeometry.width / 2,
        -faceGeometry.height / 2,
        verso ? -0.1 : 2,
      ]}
      visible={layerIsEnabled(layerName, layersView)}
      rotation={verso ? [0, Math.PI, 0] : [0, 0, 0]}
    >
      {shaderMaterialMode ? (
        <animated.shaderMaterial
          ref={materialRef}
          onUpdate={(self: Material) => (self.needsUpdate = true)}
          needsUpdate={true}
          uniformsNeedUpdate={true}
          attach="material"
          uniforms={verso ? foilUniformsVerso : foilUniformsRecto}
          vertexShader={vertexShader}
          fragmentShader={fragmentShaderNormal}
          transparent
        />
      ) : (
        <animated.meshStandardMaterial
          onUpdate={(self: Material) => (self.needsUpdate = true)}
          attach="material"
          map={foil}
          transparent
          opacity={animatedOpacity}
          color={0x333333}
          roughness={0.1}
          metalness={0.6}
          emissive={0xbf8563}
          normalMap={normalMap}
          normalScale={new Vector2(1, 1)}
          envMap={envMap}
          envMapIntensity={1}
        />
      )}
    </mesh>
  );
};
export default Foil;