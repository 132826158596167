import React, { useEffect, useRef } from "react";
import {
  extend,
  Overwrite,
  ReactThreeFiber,
  useFrame,
  useThree,
} from "react-three-fiber";
import { Vector3 } from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { Coords3D } from "../Domain";
import { Annotations } from "../Domain/Annotations";

extend({ OrbitControls });

declare global {
  namespace JSX {
    interface IntrinsicElements {
      orbitControls: Overwrite<
        ReactThreeFiber.Object3DNode<OrbitControls, typeof OrbitControls>,
        // Overwrite target prop with more permissive Vector3 type from react-three-fiber
        { target: ReactThreeFiber.Vector3 }
      >;
    }
  }
}

export type ControlProps = {
  autoRotate: boolean;
  enablePan: boolean;
  enableZoom: boolean;
  position?: Coords3D;
  target?: Coords3D;
  packagingSize?: number;
  selectedAnnotation: Annotations | undefined;
};

export const Controls = ({
  autoRotate,
  enablePan,
  enableZoom,
  position,
  target,
  packagingSize,
  selectedAnnotation,
}: ControlProps) => {
  const controls = useRef<OrbitControls>(null!);
  const { camera, gl } = useThree();
  useFrame(() => {
    controls.current.update();
  });

  // Deactivate autorotate when user manipulates camera
  useEffect(() => {
    controls.current.addEventListener("start", () => {
      controls.current.autoRotate = false;
    });
  });

  useEffect(() => {
    if (selectedAnnotation) {
      controls.current.autoRotate = false;
    }
  }, [selectedAnnotation]);

  useEffect(() => {
    if (selectedAnnotation) {
      if (target) {
        if (packagingSize) {
          const center = mapCameraToFace(
            selectedAnnotation.face,
            target,
            packagingSize,
            selectedAnnotation.isVerso
          );
          if (center) {
            camera.position.set(center.x, center.y, center.z);
          }
        }
      }
    }
  }, [selectedAnnotation]);

  // Update camera position
  useEffect(() => {
    if (position) {
      camera.position.fromArray(position);
      if (packagingSize) {
        controls.current.maxDistance = packagingSize * 10;
        controls.current.minDistance = packagingSize / 5;
        camera.near = packagingSize / 100;
        camera.far = packagingSize * 1000;
        camera.updateProjectionMatrix();
      }
      controls.current.update();
    }
  }, [camera, position, packagingSize]);

  return (
    <orbitControls
      ref={controls}
      args={[camera, gl.domElement]}
      autoRotate={autoRotate}
      autoRotateSpeed={2}
      enablePan={enablePan}
      enableZoom={enableZoom}
      enableDamping
      target={target ?? [0, 0, 0]}
    />
  );
};

Controls.defaultProps = {
  autoRotate: true,
  enablePan: false,
  enableZoom: false,
};

//TODO : Calculate center of face to get correct dynamical coordinate

function mapCameraToFace(
  faceName: string,
  target: Coords3D,
  packagingSize: number,
  isVerso: boolean | undefined
) {
  switch (faceName) {
    case "bot":
      return new Vector3(target[0], target[1] - packagingSize, target[2]);
    case "top":
      return new Vector3(target[0], target[1] + packagingSize, target[2]);
    case "front":
      return isVerso
        ? new Vector3(target[0], target[1], target[2] - packagingSize)
        : new Vector3(target[0], target[1], target[2] + packagingSize);
    case "back":
      return new Vector3(target[0], target[1], target[2] - packagingSize);
    case "right":
      return new Vector3(target[0] + packagingSize, target[1], target[2]);
    case "left":
      return new Vector3(target[0] - packagingSize, target[1], target[2]);
    default:
      return new Vector3(target[0], target[1], target[2] + packagingSize);
  }
}
