import { Dieline } from "./Dieline";
import { LayerEntity } from "./LayerEntity";

export interface Product {
  // TODO: rename to staticName
  name?: string;
  dieline: Dieline;
  paper?: {
    layer: LayerEntity;
  };
  design?: ProductDesign;
  useMask: boolean;

  // Rotation applied to the 3D object of the product in the scene
  // For instance, packagings are rotated along the x axis so that the front face does face the camera
  // (otherwise, the bottom face would face the camera as it is the reference object of the packaging space,
  // by convention)
  readonly rotation: [number, number, number];
}

export type BlankProduct = Omit<Product, "design"> & {
  design: undefined;
};

export type DesignPage = {
  color: {
    layer: LayerEntity;
  };

  // TODO: replace fixed foldFoil and silverFoil layers by an array of foil layers
  goldFoil?: {
    layer: LayerEntity;
  };
  silverFoil?: {
    layer: LayerEntity;
  };
  varnish?: {
    layer: LayerEntity;
  };
  holoFoil?: {
    layer: LayerEntity;
  };
  normalMap?: {
    layer: LayerEntity;
  };
  displacementMap?: {
    layer: LayerEntity;
  };
  bumpMap?: {
    layer: LayerEntity;
  };
  mask?: {
    layer: LayerEntity;
  };
};

export type ProductDesign = {
  recto: DesignPage;
  verso?: DesignPage;
  mask?: LayerEntity;
};

export type CustomizedProduct = Omit<Product, "design"> & {
  design: ProductDesign;
};

// TODO: rename to isCustomizedProduct()
export function isCustomizedPackaging(
  product: Product
): product is CustomizedProduct {
  return "design" in product && product.design !== undefined;
}

// TODO: rename to resolveProductImageURL()
export function resolvePackagingImageURL(
  packagingName: string | undefined,
  imagePathOrURL: string
): string {
  let imageURL: string;
  try {
    imageURL = new URL(imagePathOrURL).toString();
  } catch (_) {
    if (packagingName) {
      imageURL = `${process.env.PUBLIC_URL}/${packagingName}/${imagePathOrURL}`;
    } else {
      throw new Error(
        "Image path found on packaging without a name - Cannot resolve URL"
      );
    }
  }
  return imageURL;
}
