import React, { Ref, useRef, useState } from "react";
import { Color, Object3D } from "three";
import { Coords3D, LayerView, Product, SmartLabel } from "../Domain";
import { Annotations } from "../Domain/Annotations";
import { defaultFoilMapping, FoilMapping } from "../Domain/Foil";
import { Controls } from "./Controls";
import { VarnishMaterialProps } from "./Layers/Varnish";
import { LedProps, Leds } from "./Leds";
import { Packaging3D } from "./Packaging3D";

export type PaperMaterial = {
  name: string;
  envMapIntensity: number;
  normalScaleFactor: number;
  vecNormalScale: number;
  roughness: number;
  emissive?: number;
};

// TODO: Rename to ProductView
export type CreationState = {
  packaging: Product;
  smartLabels: SmartLabel[];
  enableZoom?: boolean;
  gltfMode: boolean;
  cancelImport: boolean;
  shaderMaterialMode: boolean;
  layersView: LayerView[];
  annotations: Annotations[];
  selectedAnnotation?: Annotations;
};

export type CameraProps = {
  target?: Coords3D;
  position?: Coords3D;
  packagingSize?: number;
};

export type SceneProps = {
  creationState: CreationState;
  ledProps: Pick<
    LedProps,
    | "ledIntensity"
    | "sphereSize"
    | "distance"
    | "decay"
    | "sphereColor"
    | "lightColor"
  >;
  varnishMaterialProps: VarnishMaterialProps;
  splinterCell: Boolean;
  opacity: number;
  enableAxesHelper: boolean;
  rotationFactor: number;
  enlightened: number;
  forwardedRef: Ref<Object3D>;
  folded: boolean;
  onNewAnnotation?: React.Dispatch<Annotations>;
  cancelNewAnnotation?: React.Dispatch<void>;
  useBluredHeightMap: boolean;
  displayedMap: string;
  spriteMaterialMode: boolean;
  annotationSize: number;
  activePaper: PaperMaterial;
  annotationMode: "display" | "pendingPlacement" | "pendingValidation";
  handleDeleteEvent?: React.Dispatch<Annotations>;
  onSelect3DAnnotation?: React.Dispatch<Annotations>;
  active360Background?: string;
  foilMapping: FoilMapping;
  foilDiffuseColor: string;
  showOnBottle: boolean;
};
export const PreviewScene = ({
  creationState,
  ledProps,
  opacity,
  enableAxesHelper,
  rotationFactor,
  forwardedRef,
  folded,
  varnishMaterialProps,
  onNewAnnotation,
  cancelNewAnnotation,
  useBluredHeightMap,
  displayedMap,
  spriteMaterialMode,
  annotationSize,
  activePaper,
  annotationMode,
  handleDeleteEvent,
  onSelect3DAnnotation,
  active360Background,
  foilMapping,
  foilDiffuseColor,
  showOnBottle,
}: SceneProps) => {
  const { packaging, enableZoom } = creationState;

  const [cameraState, setCameraState] = useState<CameraProps>({});

  //const RGBfoilDiffuseColor = hexToRgb(foilDiffuseColor);

  const packagingElement: JSX.Element = (
    <Packaging3D
      creationState={creationState}
      dieline={packaging.dieline}
      enableAxesHelper={enableAxesHelper}
      opacity={opacity}
      rotationFactor={rotationFactor}
      ledProps={ledProps}
      folded={folded}
      updateCamera={setCameraState}
      onNewAnnotation={onNewAnnotation}
      cancelNewAnnotation={cancelNewAnnotation}
      varnishMaterialProps={varnishMaterialProps}
      useBluredHeightMap={useBluredHeightMap}
      displayedMap={displayedMap}
      spriteMaterialMode={spriteMaterialMode}
      annotationSize={annotationSize}
      activePaper={activePaper}
      annotationMode={annotationMode}
      handleDeleteEvent={handleDeleteEvent}
      onSelect3DAnnotation={onSelect3DAnnotation}
      active360Background={active360Background}
      foilMapping={foilMapping}
      foilDiffuseColor={foilDiffuseColor}
      showOnBottle={showOnBottle}
    />
  );

  const target = useRef();
  // TODO: Calculate light position from packaging center
  //const initialLightPosition: Coords3D = [500, 500, 2000];
  //const initialLightPosition2: Coords3D = [9000, -500, -12000];

  return (
    <>
      <Controls
        selectedAnnotation={creationState.selectedAnnotation}
        {...{ enableZoom, ...cameraState }}
      />
      <scene ref={forwardedRef}>
        {/* Dummy object to set light target */}
        <mesh position={cameraState?.target} ref={target} />

        <ambientLight color={new Color(0x404040)} intensity={5} />

        {packagingElement}
      </scene>
      {enableAxesHelper && <axesHelper args={[5]} />}
    </>
  );
};

const defaultProps: Omit<
  SceneProps,
  | "creationState"
  | "opacity"
  | "onNewAnnotation"
  | "setLoadedTextureCache"
  | "handleDeleteEvent"
> = {
  ledProps: Leds.defaultProps,
  splinterCell: false,
  enableAxesHelper: false,
  rotationFactor: 1,
  folded: true,
  enlightened: 3.6,
  forwardedRef: null,
  varnishMaterialProps: {
    roughness: 0.1,
    metalness: 0.01,
    reflectivity: 1.4,
    envMapIntensity: 1.2,
    normalScaleFactor: 0.015,
    vecXNormalScale: 20.0,
    vecYNormalScale: 30.0,
  },

  useBluredHeightMap: true,
  displayedMap: "REGULAR_MAP",
  spriteMaterialMode: false,
  annotationSize: 100,
  activePaper: {
    name: "WhiteMatte",
    envMapIntensity: 0,
    normalScaleFactor: 0.01,
    vecNormalScale: 4.0,
    roughness: 1,
  },
  annotationMode: "display",
  foilMapping: defaultFoilMapping,
  foilDiffuseColor: "#ffcc80",
  showOnBottle: false,
};

PreviewScene.defaultProps = defaultProps;
