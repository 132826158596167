import React, { useEffect, useMemo } from "react";
import {
  DoubleSide,
  LinearFilter,
  RepeatWrapping,
  Texture,
  TextureLoader,
} from "three";
import { SmartLabelIntersection, Coords3D } from "../Domain";
import { LedDisplayProps, Leds } from "./Leds";

export function SmartLabelIntersection3D({
  intersection,
  absolutePosition,
  enableAxesHelper,
  ledProps,
}: {
  intersection: SmartLabelIntersection;
  absolutePosition: Coords3D;
  enableAxesHelper: boolean;
  ledProps: LedDisplayProps;
}) {
  const texture: Texture = useMemo(() => {
    const loader = new TextureLoader();
    const texture = loader.load(
      `${process.env.PUBLIC_URL}/${intersection.smartLabel.entity.urlJpgCanvas}`
    );
    texture.minFilter = LinearFilter;
    return texture;
  }, [intersection.smartLabel.entity]);
  // Scale and offset the smart label texture according to the intersection size and offset
  useEffect(() => {
    const { smartLabel, width, height, offset } = intersection;
    texture.offset.set(
      offset.x / smartLabel.width,
      // The y-axis offset is inverted in 3D, which complicates the calculation
      (smartLabel.height - offset.y - height) / smartLabel.height
    );
    texture.wrapS = RepeatWrapping;
    texture.wrapT = RepeatWrapping;
    texture.repeat.set(width / smartLabel.width, height / smartLabel.height);
  }, [intersection, texture]);
  const {
    smartLabel: { entity },
  } = intersection;
  return (
    <mesh
      position={[
        intersection.x - absolutePosition[0],
        -intersection.y - absolutePosition[1],
        -1,
      ]}
    >
      <planeBufferGeometry
        attach="geometry"
        args={[intersection.width, intersection.height]}
      />
      <meshPhongMaterial
        attach="material"
        side={DoubleSide}
        color={0xebe2d9}
        map={texture}
      />
      {enableAxesHelper && <axesHelper args={[5]} />}
      {!intersection.smartLabel.hideLEDs && (
        <Leds
          {...ledProps}
          ledPositions={intersection.ledPositions}
          ledType={entity.ledType}
        />
      )}
    </mesh>
  );
}
