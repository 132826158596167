import {
  CubeReflectionMapping,
  LinearFilter,
  RepeatWrapping,
  Texture,
  TextureLoader,
  Vector2,
  WebGLRenderer,
  WebGLRenderTarget,
} from "three";
import {
  DesignPage,
  FaceGeometry,
  isCustomizedPackaging,
  Product,
  resolvePackagingImageURL,
} from "../Domain";
import { blurShader } from "./blurShader";
import { heightMapCombineShader } from "./NormalMapping/heightMapCombineShader";
import { initRenderTargetTexture } from "./NormalMapping/useRenderTargetTexture";
import { normalMapShader } from "./normalMapShader";

// TODO: rename to plain texture cache
export type PackagingTextureCache = {
  globalTextures: GlobalTextureMap;
  recto: PageMap;
  verso?: PageMap;
};

export type GlobalTextureMap = {
  black: Texture;
  paper?: Texture;
  sampNoise: Texture;
  normalNoise: Texture;
  foilNormalNoise: Texture;
  envMap?: Texture;
  envMapBlured?: Texture;
  mask?: Texture;
};

export type PageMap = {
  color?: Texture;
  goldFoil?: Texture;
  silverFoil?: Texture;
  holoFoil?: Texture;
  varnish?: Texture;
  normalMapRenderTarget?: WebGLRenderTarget;
};

/**
 * Loads an image into a three.js Texture object
 * @param loader Function that loads the texture image file from a URL or a file path
 *
 * TODO: rename to initTextureCache
 */
export async function initPackagingTextureCache(
  product: Product,
  active360Background: string | undefined,
  loader = loadTexture
): Promise<PackagingTextureCache | undefined> {
  if (isCustomizedPackaging(product)) {
    const [
      black,
      paper,
      sampNoise,
      normalNoise,
      foilNormalNoise,
      mask,
    ] = await Promise.all([
      loadBlackTexture(loader),
      loadPaperTexture(loader, product.paper?.layer.url),
      loader(`${process.env.PUBLIC_URL}/papers/sampNoise2.png`),
      loader(`${process.env.PUBLIC_URL}/testNormal.jpg`),
      loader(`${process.env.PUBLIC_URL}/foilNormalNoise.jpg`),
      // Disable mask for packagings (not ready yet)
      product.useMask
        ? loadPackagingTexture(loader, product, product.design.mask?.url)
        : undefined,
    ]);
    const envMap = await loadEnvMapTexture(loader, active360Background, false);
    const envMapBlured = await loadEnvMapTexture(
      loader,
      active360Background,
      true
    );

    const rectoTextures = await loadPageTextures(
      loader,
      product,
      product.design.recto
    );
    const versoTexutres =
      product.design.verso &&
      (await loadPageTextures(loader, product, product.design.verso));

    if (!black) {
      throw new Error("could not load black texture");
    }
    if (!sampNoise) {
      throw new Error("could not load sampNoise texture");
    }
    if (!normalNoise) {
      throw new Error("could not load normalNoise texture");
    }
    if (!foilNormalNoise) {
      throw new Error("could not load foilNormalNoise texture");
    }

    sampNoise.wrapS = RepeatWrapping;
    sampNoise.wrapT = RepeatWrapping;

    normalNoise.wrapS = RepeatWrapping;
    normalNoise.wrapT = RepeatWrapping;
    normalNoise.generateMipmaps = true;

    foilNormalNoise.wrapS = RepeatWrapping;
    foilNormalNoise.wrapT = RepeatWrapping;
    foilNormalNoise.generateMipmaps = true;

    const globalLayers: GlobalTextureMap = {
      black,
      paper,
      sampNoise,
      normalNoise,
      foilNormalNoise,
      envMap,
      envMapBlured,
      mask,
    };

    if (globalLayers.paper) {
      globalLayers.paper.wrapS = RepeatWrapping;
      globalLayers.paper.wrapT = RepeatWrapping;
    }

    if (envMap) {
      envMap.mapping = CubeReflectionMapping;
    }
  
    if (envMapBlured) {
      envMapBlured.mapping = CubeReflectionMapping;
    }


    return {
      globalTextures: globalLayers,
      recto: rectoTextures,
      verso: versoTexutres,
    };
  } else {
    return undefined;
  }
}

async function loadPageTextures(
  loader: (url: string) => Promise<Texture | undefined>,
  product: Product,
  designPage: DesignPage
): Promise<PageMap> {
  const [color, goldFoil, silverFoil, holoFoil, varnish] = await Promise.all([
    loadPackagingTexture(loader, product, designPage.color.layer.url),
    loadPackagingTexture(loader, product, designPage.goldFoil?.layer.url),
    loadPackagingTexture(loader, product, designPage.silverFoil?.layer.url),
    loadPackagingTexture(loader, product, designPage.holoFoil?.layer.url),
    loadPackagingTexture(loader, product, designPage.varnish?.layer.url),
  ]);
  return {
    color,
    goldFoil,
    silverFoil,
    holoFoil,
    varnish,
  };
}

export async function computeRectoAndVersoNormalMap(
  gl: WebGLRenderer,
  textureCache: PackagingTextureCache,
  useBluredHeightMap: boolean
): Promise<PackagingTextureCache> {
  const newRecto = computeNormalMapDesignMap(
    gl,
    textureCache,
    textureCache.recto,
    useBluredHeightMap
  );
  const newVerso =
    textureCache.verso &&
    computeNormalMapDesignMap(
      gl,
      textureCache,
      textureCache.verso,
      useBluredHeightMap
    );

  return { ...textureCache, recto: newRecto, verso: newVerso };
}

export function computeNormalMapDesignMap(
  gl: WebGLRenderer,
  textureCache: PackagingTextureCache,
  pageMap: PageMap,
  useBluredHeightMap: boolean
): PageMap {
  const { black } = textureCache.globalTextures;
  const textures =
    pageMap.varnish || pageMap.silverFoil || pageMap.goldFoil
      ? [
          pageMap.varnish ?? black,
          pageMap.silverFoil ?? black,
          pageMap.goldFoil ?? black,
        ]
      : undefined;

  const heightMap =
    textures && initRenderTargetTexture(gl, textures, heightMapCombineShader);

  const heightMapBlured = useBluredHeightMap
    ? initRenderTargetTexture(
        gl,
        heightMap ? [heightMap?.texture] : undefined,
        blurShader
      )
    : heightMap;

  const normalMap = initRenderTargetTexture(
    gl,
    heightMapBlured && heightMap
      ? [heightMap.texture, heightMapBlured.texture]
      : undefined,
    normalMapShader
  );

  const newPageMap: PageMap = {
    ...pageMap,
    normalMapRenderTarget: normalMap,
  };

  return newPageMap;
}

export type TexturePromiseLoader = (
  url: string
) => Promise<Texture | undefined>;

function loadTexture(url: string): Promise<Texture | undefined> {
  const loader = new TextureLoader();
  return new Promise<Texture | undefined>((resolve, reject) => {
    loader.load(
      url.toString(),
      (texture) => {
        texture.minFilter = LinearFilter;
        resolve(texture);
      },
      () => {},
      reject
    );
  });
}

function loadBlackTexture(loader: TexturePromiseLoader) {
  return loader(`${process.env.PUBLIC_URL}/black.png`);
}

// TODO: rename to loadProductTexture()
async function loadPackagingTexture(
  loader: TexturePromiseLoader,
  packaging: Product,
  imagePathOrURL: string | undefined
) {
  if (imagePathOrURL === undefined) {
    return undefined;
  }
  const imageURL = resolvePackagingImageURL(packaging.name, imagePathOrURL);
  return loader(imageURL);
}

function loadPaperTexture(
  loader: TexturePromiseLoader,
  paperName: string | undefined
) {
  if (paperName === undefined) {
    return undefined;
  }
  const paperURL = `${process.env.PUBLIC_URL}/papers/${paperName}`;
  return loader(paperURL);
}

function loadEnvMapTexture(
  loader: TexturePromiseLoader,
  active360Background: string | undefined,
  blured: boolean
) {
  if (active360Background === undefined) {
    return blured
      ? loader(`${process.env.PUBLIC_URL}/envMap/defaultEnvMap_blured.png`)
      : loader(`${process.env.PUBLIC_URL}/envMap/defaultEnvMap.png`);
  }
  const envMapURL = blured
    ? `${process.env.PUBLIC_URL}/envMap/${active360Background}_blured.jpg`
    : `${process.env.PUBLIC_URL}/envMap/${active360Background}.jpg`;
  return loader(envMapURL);
}

export function computeFaceUVs(
  product: Product,
  faceName: string
): { repeat: Vector2; offset: Vector2 } {
  const { width: packWidth, height: packHeight } = product.dieline.dimensions;
  const faceGeometry: FaceGeometry = product.dieline.faceGeometries[faceName];
  const offset = new Vector2(
    faceGeometry.x / packWidth,
    (packHeight - faceGeometry.y - faceGeometry.height) / packHeight
  );
  const repeat = new Vector2(
    faceGeometry.width / packWidth,
    faceGeometry.height / packHeight
  );
  return { repeat, offset };
}
