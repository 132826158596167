import { FaceGeometryMap } from "./Face";
import {
  applyRotationFunction,
  deriveRotationAxisFromFaceGeometries,
  FoldingTree,
  MinimalFoldingTree,
} from "./FoldingTree";

export type Dieline = {
  name: string;
  pdfURL?: string;
  aiURL?: string;
  faceGeometries: FaceGeometryMap;
  // Dimensions of the 2D dieline
  dimensions: {
    width: number;
    height: number;
  };
  foldingTree: FoldingTree;
};

export type DielineJson = {
  name: string;
  pdfURL?: string;
  aiURL?: string;
  faceGeometries: FaceGeometryMap;
  dimensions: {
    width: number;
    height: number;
  };
  foldingTree: MinimalFoldingTree;
};

export function deriveRotationAxes(dielineJson: DielineJson): Dieline {
  const { faceGeometries, foldingTree } = dielineJson;
  const deriveRotationAxis = (
    parentFaceName: string,
    childFaceName: string,
    rotationAngle?: number
  ) => {
    return deriveRotationAxisFromFaceGeometries(
      faceGeometries[parentFaceName],
      faceGeometries[childFaceName],
      rotationAngle
    );
  };
  return {
    ...dielineJson,
    foldingTree: applyRotationFunction(deriveRotationAxis, foldingTree),
  };
}
