export type SmartLabelEntity = {
  id: number;
  name: string;
  scale: number;
  width: number;
  height: number;
  label: Array<string>;
  urlJpg: string;
  urlJpgCanvas: string;
  ledType: LedAnimationType;
  leds: [number, number][];
};

export type LedAnimationType = "chaser" | "blink";

export async function getSmartLabel(): Promise<SmartLabelEntity[]> {
  const response = await fetch(
    `${process.env.PUBLIC_URL}/SmartLabel/SmartLabel.json`
  );
  const smartLabels: SmartLabelEntity[] = await response.json();
  return smartLabels;
}
