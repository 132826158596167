import { Dieline } from "../Dieline";
import { LayerEntity } from "../LayerEntity";
import {
  BlankProduct,
  CustomizedProduct,
  DesignPage,
  Product,
  ProductDesign,
} from "../Product";
import SmartLabel from "../SmartLabel";

export type SmartPackaging = {
  packaging: Packaging;
  smartlabel?: SmartLabel[];
};

export class Packaging implements Product {
  public readonly rotation = [Math.PI / 2, 0, 0] as [number, number, number];
  useMask = false;

  name?: string;
  dieline: Dieline;
  paper?: { layer: LayerEntity };
  design?: ProductDesign;

  constructor(props: {
    name?: string;
    paper?: { layer: LayerEntity };
    dieline: Dieline;
    sceneBackgroundColor?: string;
    design?: ProductDesign | DesignPage;
    initial3dRotation?: [number, number, number];
  }) {
    this.name = props.name;
    this.dieline = props.dieline;
    this.paper = props.paper;
    if (props.design) {
      this.design =
        "recto" in props.design ? props.design : { recto: props.design };
    }
    if (props.initial3dRotation) {
      this.rotation = props.initial3dRotation;
    }
  }
}

export class BlankPackaging extends Packaging implements BlankProduct {
  design = undefined;

  constructor({
    dieline,
    paper,
    initial3dRotation,
  }: {
    dieline: Dieline;
    paper?: { layer: LayerEntity };
    initial3dRotation?: [number, number, number];
  }) {
    super({ dieline, paper, design: undefined, initial3dRotation });
  }
}

export class CustomizedPackaging
  extends Packaging
  implements CustomizedProduct {
  design: ProductDesign;

  constructor(props: {
    name?: string;
    paper?: { layer: LayerEntity };
    dieline: Dieline;
    sceneBackgroundColor?: string;
    design: ProductDesign | DesignPage;
    initial3dRotation?: [number, number, number];
  }) {
    super(props);
    this.design =
      "recto" in props.design ? props.design : { recto: props.design };
  }
}
