import { useMemo } from "react";
import { Texture, TextureLoader } from "three";

// White png image
// Dimensions : 4x4 pixels
const whitePngDataUri =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAIAAAAmkwkpAAAC6npUWHRSYXcgcHJvZmlsZSB0eXBlIGV4aWYAAHja7ZdtkiMnDIb/c4ocAUkIiePQfFTlBjl+Xuhujz0zSXZr90+q3FQDFiAJPQLbYfz15wx/4KGSOSQ1zyXniCeVVLii4/F8yq4ppl2fT7paepWHxwBDJGjl/Gj1ml8h148Ftw06XuXBrxH2S9Ft+VIoyzKj05+dhJxPOV2OhDLOTi5uz64efLbt9tg/3tLPfdCldX0Oz4JkiFJXGBLmISRx1+n0QM634i2oIcc8Qll9CXvg3hIC8rK9u43xOUCvwb964XP0H71Pwed6yeVTLPNNLX8/QPp98HeInwzLwyN+HTC5VX0N8pzd5xzn7mrKiGi+MiqGOzprDSYeCLnsZRnF8Cr6tktB8VhjA/IeWzxQGhViUJmBEnWqNGnstlGDi4kHG1rmxrJlLsaFmyxOaRWabKDXxcGy8QgiEPPDF9p2y7bXyGG5E6YyQRlhyT+W8G+DP1PCnG2FiKI/YgW/eOU13FjkVo1ZAELz4qY7wHe58Men/EGqgqDuMDs2WONxqjiUPnJLNmfBPEV7HiEK1i8FCBFsK5xB2ieKmUQpUzRmI0IcHYAqPGecjQMESJU7nOQkgvvI2HnZxhqjPZeVMy8x7iaAUMliYIPzBVgpKfLHkiOHqoomVc1q6kGL1iw5Zc05W16XXDWxZGrZzNyKVRdPrp7d3L14LVwEd6CWXKx4KaVWDhWGKnRVzK+QHHzIkQ498mGHH+WoDenTUtOWmzVvpdXOXTquiZ67de+l10Fh4KYYaejIw4aPMupErk2ZaerM06bPMuuD2kX1S/kJanRR401qzbMHNUiD2a2C1nWiixmIcSIQt0UACc2LWXRKiRe5xSwWxqFQhpO62IROixgQpkGskx7sPsj9ELeg/kPc+L/IhYXud5ALQPeV2zfU+vqea5vYeQpXTKPg9GFOZQ94Y0T1q+1b0VvRW9Fb0VvRW9Fb0f9fkUz8eMC/y/A3m0aduIzTtC0AAAGFaUNDUElDQyBwcm9maWxlAAB4nH2RPUjDUBSFT1OlKlURO4g4ZKhOFkRFHKWKRbBQ2gqtOpi89A+aNCQpLo6Ca8HBn8Wqg4uzrg6ugiD4A+Lk6KToIiXelxRaxPjg8j7Oe+dw332AUC8z1eyYAFTNMpKxqJjJroqBV3SjjyqAAYmZejy1mIbn+rqHj+93EZ7lfe/P1avkTAb4ROI5phsW8QbxzKalc94nDrGipBCfE48b1CDxI9dll984FxwWeGbISCfniUPEYqGN5TZmRUMlniYOK6pG+ULGZYXzFme1XGXNPvkLgzltJcV1qhHEsIQ4EhAho4oSyrAQoV0jxUSSzqMe/mHHnyCXTK4SGDkWUIEKyfGD/8Hv2Zr5qUk3KRgFOl9s+2MUCOwCjZptfx/bduME8D8DV1rLX6kDs5+k11pa+Ajo3wYurluavAdc7gBDT7pkSI7kpxLyeeD9jL4pCwzeAj1r7tya5zh9ANI0q+Ub4OAQGCtQ9rrHu7va5/bvneb8fgAWVnKCwNKpwgAAAAlwSFlzAAASdAAAEnQB3mYfeAAAAAd0SU1FB+QJDgkNA4+48g4AAAAUSURBVAjXY/z//z8DDDAxIAHcHACWbgMF2hiSxQAAAABJRU5ErkJggg==";

export function useWhiteTexture(): Texture {
  return useMemo(() => {
    const loader = new TextureLoader();
    return loader.load(whitePngDataUri);
  }, []);
}
