import React, { useMemo } from "react";
import { useSpring } from "react-spring";
import { PlaneBufferGeometry, Texture } from "three";
import { BufferGeometryUtils } from "three/examples/jsm/utils/BufferGeometryUtils";
import { FaceGeometry, FoldingTree, Product } from "../Domain";
import { Annotations } from "../Domain/Annotations";
import { FoilMapping } from "../Domain/Foil";
import ColorLayer from "./Layers/ColorLayer";
import Foil from "./Layers/Foil";
import HoloFoil from "./Layers/HoloFoil";
import Paper from "./Layers/Paper";
import Varnish, { VarnishMaterialProps } from "./Layers/Varnish";
import { computeFaceUVs, PackagingTextureCache } from "./PackagingTextureCache";
import { CreationState, PaperMaterial } from "./PreviewScene";

export type DesignFaceProps = {
  node: FoldingTree;
  creationState: CreationState;
  opacity: number;
  textureCache?: PackagingTextureCache;
  onNewAnnotation?: React.Dispatch<Annotations>;
  envMap?: Texture;
  varnishMaterialProps: VarnishMaterialProps;
  displayedMap: string;
  annotationSize: number;
  activePaperName: PaperMaterial;
  faceGeometry: FaceGeometry;
  packaging: Product;
  verso: boolean;
  foilMapping: FoilMapping;
  foilDiffuseColor: string;
};

const DesignFace = ({
  node,
  creationState,
  opacity,
  textureCache,
  onNewAnnotation,
  envMap,
  varnishMaterialProps,
  displayedMap,
  activePaperName,
  faceGeometry,
  packaging,
  verso,
  foilMapping,
  foilDiffuseColor,
}: DesignFaceProps) => {
  const pageMap = verso ? textureCache?.verso : textureCache?.recto;

  const mask = textureCache?.globalTextures.mask;

  const paper = textureCache?.globalTextures.paper;

  const { opacity: animatedOpacity } = useSpring({ opacity });

  const geometry = useMemo(
    () =>
      new PlaneBufferGeometry(faceGeometry.width, faceGeometry.height, 1, 1),
    [faceGeometry]
  );

  const faceUVs = useMemo(() => {
    return computeFaceUVs(packaging, node.faceName);
  }, [packaging, node.faceName]);

  BufferGeometryUtils.computeTangents(geometry);

  const parallaxScale = 0.001;

  const parallaxMinLayers = 32.0;
  const parallaxMaxLayers = 64.0;

  const sampNoise = textureCache?.globalTextures.sampNoise;

  return (
    <>
      {
        <mesh
          name={node.faceName}
          geometry={geometry}
          position={[faceGeometry.width / 2, -faceGeometry.height / 2, 1]}
        >
          <meshBasicMaterial attach="material" opacity={0} transparent={true} />
        </mesh>
      }
      {paper && (
        <Paper
          geometry={geometry}
          faceGeometry={faceGeometry}
          paper={paper}
          sampNoise={sampNoise}
          animatedOpacity={animatedOpacity}
          faceName={node.faceName}
          layersView={creationState.layersView}
          faceUVs={faceUVs}
          envMap={envMap}
          activePaper={activePaperName}
          normalNoise={textureCache?.globalTextures.normalNoise}
          mask={mask}
        />
      )}
      {pageMap?.color && (
        <ColorLayer
          geometry={geometry}
          faceGeometry={faceGeometry}
          map={pageMap?.color}
          normalMap={pageMap?.normalMapRenderTarget?.texture}
          animatedOpacity={animatedOpacity}
          faceName={node.faceName}
          shaderMaterialMode={creationState.shaderMaterialMode}
          onNewAnnotation={onNewAnnotation}
          parallaxScale={parallaxScale}
          parallaxMinLayers={parallaxMinLayers}
          parallaxMaxLayers={parallaxMaxLayers}
          layersView={creationState.layersView}
          faceUVs={faceUVs}
          verso={verso}
          mask={mask}
        />
      )}
      {pageMap?.holoFoil && (
        <HoloFoil
          shaderMaterialMode={creationState.shaderMaterialMode}
          geometry={geometry}
          faceGeometry={faceGeometry}
          holoFoil={pageMap.holoFoil}
          animatedOpacity={animatedOpacity}
          faceName={node.faceName}
          faceUVs={faceUVs}
          verso={verso}
        />
      )}
      //TODO: replace fixed foldFoil and silverFoil layers by an array of foil layers
      
      
      {pageMap?.silverFoil && (
        <Foil
          layerName="silverFoil"
          foilNormalNoise={textureCache?.globalTextures.foilNormalNoise}
          shaderMaterialMode={creationState.shaderMaterialMode}
          geometry={geometry}
          faceGeometry={faceGeometry}
          normalMap={pageMap.normalMapRenderTarget?.texture}
          foil={pageMap.silverFoil}
          animatedOpacity={animatedOpacity}
          faceName={node.faceName}
          envMap={envMap}
          parallaxScale={parallaxScale}
          parallaxMinLayers={parallaxMinLayers}
          parallaxMaxLayers={parallaxMaxLayers}
          layersView={creationState.layersView}
          faceUVs={faceUVs}
          verso={verso}
          mask={mask}
          foilMaterial={foilMapping.silverFoil}
          foilDiffuseColor={foilDiffuseColor}
        />
      )}
      {pageMap?.goldFoil && (
        <Foil
          layerName="goldFoil"
          foilNormalNoise={textureCache?.globalTextures.foilNormalNoise}
          shaderMaterialMode={creationState.shaderMaterialMode}
          geometry={geometry}
          faceGeometry={faceGeometry}
          normalMap={pageMap.normalMapRenderTarget?.texture}
          foil={pageMap.goldFoil}
          animatedOpacity={animatedOpacity}
          faceName={node.faceName}
          envMap={envMap}
          parallaxScale={parallaxScale}
          parallaxMinLayers={parallaxMinLayers}
          parallaxMaxLayers={parallaxMaxLayers}
          layersView={creationState.layersView}
          faceUVs={faceUVs}
          verso={verso}
          mask={mask}
          foilMaterial={foilMapping.goldFoil}
          foilDiffuseColor={foilDiffuseColor}
        />
      )}
      {pageMap?.varnish && (
        <Varnish
          shaderMaterialMode={creationState.shaderMaterialMode}
          gltfMode={creationState.gltfMode}
          geometry={geometry}
          faceGeometry={faceGeometry}
          normalMap={pageMap?.normalMapRenderTarget?.texture}
          normalNoise={textureCache?.globalTextures.normalNoise}
          varnish={pageMap.varnish}
          animatedOpacity={animatedOpacity}
          faceName={node.faceName}
          envMap={envMap}
          parallaxScale={parallaxScale}
          parallaxMinLayers={parallaxMinLayers}
          parallaxMaxLayers={parallaxMaxLayers}
          layersView={creationState.layersView}
          faceUVs={faceUVs}
          metalness={varnishMaterialProps.metalness}
          roughness={varnishMaterialProps.roughness}
          reflectivity={varnishMaterialProps.reflectivity}
          envMapIntensity={varnishMaterialProps.envMapIntensity}
          normalScaleFactor={varnishMaterialProps.normalScaleFactor}
          vecXNormalScale={varnishMaterialProps.vecXNormalScale}
          vecYNormalScale={varnishMaterialProps.vecYNormalScale}
          displayedMap={displayedMap}
          verso={verso}
          mask={mask}
        />
      )}
    </>
  );
};

export default DesignFace;
