export const paperFragmentShader = `
#extension GL_OES_standard_derivatives : enable

uniform vec3 diffuse ;
uniform vec3 emissive ;
uniform float roughness ;
uniform float metalness ;
uniform float opacity ;
uniform float transparency ;
uniform float reflectivity ;
uniform float clearcoat ;
uniform float clearcoatRoughness ;
uniform float refractionRatio ;
varying vec3 vViewPosition ;
varying vec3 vNormal ;
varying vec3 vTangent ;
varying vec3 vBitangent ;
float pow2 ( const in float x ) { return x * x ; }
float pow3 ( const in float x ) { return x * x * x ; }
float pow4 ( const in float x ) { float x2 = x * x ; return x2 * x2 ; }
float average ( const in vec3 color ) { return dot ( color , vec3 ( 0.3333 ) ) ; }
highp float rand ( const in vec2 uv ) {
const highp float a = 12.9898 , b = 78.233 , c = 43758.5453 ;
highp float dt = dot ( uv . xy , vec2 ( a , b ) ) , sn = mod ( dt , 3.141592653589793 ) ;
return fract ( sin ( sn ) * c ) ;
}
float max3 ( vec3 v ) { return max ( max ( v . x , v . y ) , v . z ) ; }
float precisionSafeLength ( vec3 v ) {
float maxComponent = max3 ( abs ( v ) ) ;
return length ( v / maxComponent ) * maxComponent ;
}
struct IncidentLight {
vec3 color ;
vec3 direction ;
bool visible ;
} ;
struct ReflectedLight {
vec3 directDiffuse ;
vec3 directSpecular ;
vec3 indirectDiffuse ;
vec3 indirectSpecular ;
} ;
struct GeometricContext {
vec3 position ;
vec3 normal ;
vec3 viewDir ;
vec3 clearcoatNormal ;
} ;
vec3 transformDirection ( in vec3 dir , in mat4 matrix ) {
return normalize ( ( matrix * vec4 ( dir , 0.0 ) ) . xyz ) ;
}
vec3 inverseTransformDirection ( in vec3 dir , in mat4 matrix ) {
return normalize ( ( vec4 ( dir , 0.0 ) * matrix ) . xyz ) ;
}
vec3 projectOnPlane ( in vec3 point , in vec3 pointOnPlane , in vec3 planeNormal ) {
float distance = dot ( planeNormal , point - pointOnPlane ) ;
return - distance * planeNormal + point ;
}
float sideOfPlane ( in vec3 point , in vec3 pointOnPlane , in vec3 planeNormal ) {
return sign ( dot ( point - pointOnPlane , planeNormal ) ) ;
}
vec3 linePlaneIntersect ( in vec3 pointOnLine , in vec3 lineDirection , in vec3 pointOnPlane , in vec3 planeNormal ) {
return lineDirection * ( dot ( planeNormal , pointOnPlane - pointOnLine ) / dot ( planeNormal , lineDirection ) ) + pointOnLine ;
}
mat3 transposeMat3 ( const in mat3 m ) {
mat3 tmp ;
tmp [ 0 ] = vec3 ( m [ 0 ] . x , m [ 1 ] . x , m [ 2 ] . x ) ;
tmp [ 1 ] = vec3 ( m [ 0 ] . y , m [ 1 ] . y , m [ 2 ] . y ) ;
tmp [ 2 ] = vec3 ( m [ 0 ] . z , m [ 1 ] . z , m [ 2 ] . z ) ;
return tmp ;
}
float linearToRelativeLuminance ( const in vec3 color ) {
vec3 weights = vec3 ( 0.2126 , 0.7152 , 0.0722 ) ;
return dot ( weights , color . rgb ) ;
}
bool isPerspectiveMatrix ( mat4 m ) {
return m [ 2 ] [ 3 ] == - 1.0 ;
}
vec2 equirectUv ( in vec3 dir ) {
float u = atan ( dir . z , dir . x ) * 0.15915494309189535 + 0.5 ;
float v = asin ( clamp ( dir . y , - 1.0 , 1.0 ) ) * 0.3183098861837907 + 0.5 ;
return vec2 ( u , v ) ;
}
vec3 packNormalToRGB ( const in vec3 normal ) {
return normalize ( normal ) * 0.5 + 0.5 ;
}
vec3 unpackRGBToNormal ( const in vec3 rgb ) {
return 2.0 * rgb . xyz - 1.0 ;
}
const float PackUpscale = 256. / 255. ;
const float UnpackDownscale = 255. / 256. ;
const vec3 PackFactors = vec3 ( 256. * 256. * 256. , 256. * 256. , 256. ) ;
const vec4 UnpackFactors = UnpackDownscale / vec4 ( PackFactors , 1. ) ;
const float ShiftRight8 = 1. / 256. ;
vec4 packDepthToRGBA ( const in float v ) {
vec4 r = vec4 ( fract ( v * PackFactors ) , v ) ;
r . yzw -= r . xyz * ShiftRight8 ;
return r * PackUpscale ;
}
float unpackRGBAToDepth ( const in vec4 v ) {
return dot ( v , UnpackFactors ) ;
}
vec4 pack2HalfToRGBA ( vec2 v ) {
vec4 r = vec4 ( v . x , fract ( v . x * 255.0 ) , v . y , fract ( v . y * 255.0 ) ) ;
return vec4 ( r . x - r . y / 255.0 , r . y , r . z - r . w / 255.0 , r . w ) ;
}
vec2 unpackRGBATo2Half ( vec4 v ) {
return vec2 ( v . x + ( v . y / 255.0 ) , v . z + ( v . w / 255.0 ) ) ;
}
float viewZToOrthographicDepth ( const in float viewZ , const in float near , const in float far ) {
return ( viewZ + near ) / ( near - far ) ;
}
float orthographicDepthToViewZ ( const in float linearClipZ , const in float near , const in float far ) {
return linearClipZ * ( near - far ) - near ;
}
float viewZToPerspectiveDepth ( const in float viewZ , const in float near , const in float far ) {
return ( ( near + viewZ ) * far ) / ( ( far - near ) * viewZ ) ;
}
float perspectiveDepthToViewZ ( const in float invClipZ , const in float near , const in float far ) {
return ( near * far ) / ( ( far - near ) * invClipZ - far ) ;
}
varying vec2 vUv ;
varying vec2 vUv2 ;
uniform sampler2D map ;
vec2 integrateSpecularBRDF ( const in float dotNV , const in float roughness ) {
const vec4 c0 = vec4 ( - 1 , - 0.0275 , - 0.572 , 0.022 ) ;
const vec4 c1 = vec4 ( 1 , 0.0425 , 1.04 , - 0.04 ) ;
vec4 r = roughness * c0 + c1 ;
float a004 = min ( r . x * r . x , exp2 ( - 9.28 * dotNV ) ) * r . x + r . y ;
return vec2 ( - 1.04 , 1.04 ) * a004 + r . zw ;
}
float punctualLightIntensityToIrradianceFactor ( const in float lightDistance , const in float cutoffDistance , const in float decayExponent ) {
if ( cutoffDistance > 0.0 && decayExponent > 0.0 ) {
return pow ( clamp ( - lightDistance / cutoffDistance + 1.0 , 0.0 , 1.0 ) , decayExponent ) ;
}
return 1.0 ;
}
vec3 BRDF_Diffuse_Lambert ( const in vec3 diffuseColor ) {
return 0.3183098861837907 * diffuseColor ;
}
vec3 F_Schlick ( const in vec3 specularColor , const in float dotLH ) {
float fresnel = exp2 ( ( - 5.55473 * dotLH - 6.98316 ) * dotLH ) ;
return ( 1.0 - specularColor ) * fresnel + specularColor ;
}
vec3 F_Schlick_RoughnessDependent ( const in vec3 F0 , const in float dotNV , const in float roughness ) {
float fresnel = exp2 ( ( - 5.55473 * dotNV - 6.98316 ) * dotNV ) ;
vec3 Fr = max ( vec3 ( 1.0 - roughness ) , F0 ) - F0 ;
return Fr * fresnel + F0 ;
}
float G_GGX_Smith ( const in float alpha , const in float dotNL , const in float dotNV ) {
float a2 = pow2 ( alpha ) ;
float gl = dotNL + sqrt ( a2 + ( 1.0 - a2 ) * pow2 ( dotNL ) ) ;
float gv = dotNV + sqrt ( a2 + ( 1.0 - a2 ) * pow2 ( dotNV ) ) ;
return 1.0 / ( gl * gv ) ;
}
float G_GGX_SmithCorrelated ( const in float alpha , const in float dotNL , const in float dotNV ) {
float a2 = pow2 ( alpha ) ;
float gv = dotNL * sqrt ( a2 + ( 1.0 - a2 ) * pow2 ( dotNV ) ) ;
float gl = dotNV * sqrt ( a2 + ( 1.0 - a2 ) * pow2 ( dotNL ) ) ;
return 0.5 / max ( gv + gl , 1e-6 ) ;
}
float D_GGX ( const in float alpha , const in float dotNH ) {
float a2 = pow2 ( alpha ) ;
float denom = pow2 ( dotNH ) * ( a2 - 1.0 ) + 1.0 ;
return 0.3183098861837907 * a2 / pow2 ( denom ) ;
}
vec3 BRDF_Specular_GGX ( const in IncidentLight incidentLight , const in vec3 viewDir , const in vec3 normal , const in vec3 specularColor , const in float roughness ) {
float alpha = pow2 ( roughness ) ;
vec3 halfDir = normalize ( incidentLight . direction + viewDir ) ;
float dotNL = clamp ( dot ( normal , incidentLight . direction ) , 0.0 , 1.0 ) ;
float dotNV = clamp ( dot ( normal , viewDir ) , 0.0 , 1.0 ) ;
float dotNH = clamp ( dot ( normal , halfDir ) , 0.0 , 1.0 ) ;
float dotLH = clamp ( dot ( incidentLight . direction , halfDir ) , 0.0 , 1.0 ) ;
vec3 F = F_Schlick ( specularColor , dotLH ) ;
float G = G_GGX_SmithCorrelated ( alpha , dotNL , dotNV ) ;
float D = D_GGX ( alpha , dotNH ) ;
return F * ( G * D ) ;
}
vec2 LTC_Uv ( const in vec3 N , const in vec3 V , const in float roughness ) {
const float LUT_SIZE = 64.0 ;
const float LUT_SCALE = ( LUT_SIZE - 1.0 ) / LUT_SIZE ;
const float LUT_BIAS = 0.5 / LUT_SIZE ;
float dotNV = clamp ( dot ( N , V ) , 0.0 , 1.0 ) ;
vec2 uv = vec2 ( roughness , sqrt ( 1.0 - dotNV ) ) ;
uv = uv * LUT_SCALE + LUT_BIAS ;
return uv ;
}
float LTC_ClippedSphereFormFactor ( const in vec3 f ) {
float l = length ( f ) ;
return max ( ( l * l + f . z ) / ( l + 1.0 ) , 0.0 ) ;
}
vec3 LTC_EdgeVectorFormFactor ( const in vec3 v1 , const in vec3 v2 ) {
float x = dot ( v1 , v2 ) ;
float y = abs ( x ) ;
float a = 0.8543985 + ( 0.4965155 + 0.0145206 * y ) * y ;
float b = 3.4175940 + ( 4.1616724 + y ) * y ;
float v = a / b ;
float theta_sintheta = ( x > 0.0 ) ? v : 0.5 * inversesqrt ( max ( 1.0 - x * x , 1e-7 ) ) - v ;
return cross ( v1 , v2 ) * theta_sintheta ;
}
vec3 LTC_Evaluate ( const in vec3 N , const in vec3 V , const in vec3 P , const in mat3 mInv , const in vec3 rectCoords [ 4 ] ) {
vec3 v1 = rectCoords [ 1 ] - rectCoords [ 0 ] ;
vec3 v2 = rectCoords [ 3 ] - rectCoords [ 0 ] ;
vec3 lightNormal = cross ( v1 , v2 ) ;
if ( dot ( lightNormal , P - rectCoords [ 0 ] ) < 0.0 ) return vec3 ( 0.0 ) ;
vec3 T1 , T2 ;
T1 = normalize ( V - N * dot ( V , N ) ) ;
T2 = - cross ( N , T1 ) ;
mat3 mat = mInv * transposeMat3 ( mat3 ( T1 , T2 , N ) ) ;
vec3 coords [ 4 ] ;
coords [ 0 ] = mat * ( rectCoords [ 0 ] - P ) ;
coords [ 1 ] = mat * ( rectCoords [ 1 ] - P ) ;
coords [ 2 ] = mat * ( rectCoords [ 2 ] - P ) ;
coords [ 3 ] = mat * ( rectCoords [ 3 ] - P ) ;
coords [ 0 ] = normalize ( coords [ 0 ] ) ;
coords [ 1 ] = normalize ( coords [ 1 ] ) ;
coords [ 2 ] = normalize ( coords [ 2 ] ) ;
coords [ 3 ] = normalize ( coords [ 3 ] ) ;
vec3 vectorFormFactor = vec3 ( 0.0 ) ;
vectorFormFactor += LTC_EdgeVectorFormFactor ( coords [ 0 ] , coords [ 1 ] ) ;
vectorFormFactor += LTC_EdgeVectorFormFactor ( coords [ 1 ] , coords [ 2 ] ) ;
vectorFormFactor += LTC_EdgeVectorFormFactor ( coords [ 2 ] , coords [ 3 ] ) ;
vectorFormFactor += LTC_EdgeVectorFormFactor ( coords [ 3 ] , coords [ 0 ] ) ;
float result = LTC_ClippedSphereFormFactor ( vectorFormFactor ) ;
return vec3 ( result ) ;
}
vec3 BRDF_Specular_GGX_Environment ( const in vec3 viewDir , const in vec3 normal , const in vec3 specularColor , const in float roughness ) {
float dotNV = clamp ( dot ( normal , viewDir ) , 0.0 , 1.0 ) ;
vec2 brdf = integrateSpecularBRDF ( dotNV , roughness ) ;
return specularColor * brdf . x + brdf . y ;
}
void BRDF_Specular_Multiscattering_Environment ( const in GeometricContext geometry , const in vec3 specularColor , const in float roughness , inout vec3 singleScatter , inout vec3 multiScatter ) {
float dotNV = clamp ( dot ( geometry . normal , geometry . viewDir ) , 0.0 , 1.0 ) ;
vec3 F = F_Schlick_RoughnessDependent ( specularColor , dotNV , roughness ) ;
vec2 brdf = integrateSpecularBRDF ( dotNV , roughness ) ;
vec3 FssEss = F * brdf . x + brdf . y ;
float Ess = brdf . x + brdf . y ;
float Ems = 1.0 - Ess ;
vec3 Favg = specularColor + ( 1.0 - specularColor ) * 0.047619 ;
vec3 Fms = FssEss * Favg / ( 1.0 - Ems * Favg ) ;
singleScatter += FssEss ;
multiScatter += Fms * Ems ;
}
float G_BlinnPhong_Implicit ( ) {
return 0.25 ;
}
float D_BlinnPhong ( const in float shininess , const in float dotNH ) {
return 0.3183098861837907 * ( shininess * 0.5 + 1.0 ) * pow ( dotNH , shininess ) ;
}
vec3 BRDF_Specular_BlinnPhong ( const in IncidentLight incidentLight , const in GeometricContext geometry , const in vec3 specularColor , const in float shininess ) {
vec3 halfDir = normalize ( incidentLight . direction + geometry . viewDir ) ;
float dotNH = clamp ( dot ( geometry . normal , halfDir ) , 0.0 , 1.0 ) ;
float dotLH = clamp ( dot ( incidentLight . direction , halfDir ) , 0.0 , 1.0 ) ;
vec3 F = F_Schlick ( specularColor , dotLH ) ;
float G = G_BlinnPhong_Implicit ( ) ;
float D = D_BlinnPhong ( shininess , dotNH ) ;
return F * ( G * D ) ;
}
float GGXRoughnessToBlinnExponent ( const in float ggxRoughness ) {
return ( 2.0 / pow2 ( ggxRoughness + 0.0001 ) - 2.0 ) ;
}
float BlinnExponentToGGXRoughness ( const in float blinnExponent ) {
return sqrt ( 2.0 / ( blinnExponent + 2.0 ) ) ;
}
uniform float envMapIntensity ;
uniform float flipEnvMap ;
uniform int maxMipLevel ;
uniform sampler2D envMap ;
vec3 getLightProbeIndirectIrradiance ( const in GeometricContext geometry , const in int maxMIPLevel ) {
vec3 worldNormal = inverseTransformDirection ( geometry . normal , viewMatrix ) ;
vec4 envMapColor = vec4 ( 0.0 ) ;
return 3.14159265359 * envMapColor . rgb * envMapIntensity ;
}
float getSpecularMIPLevel ( const in float roughness , const in int maxMIPLevel ) {
float maxMIPLevelScalar = float ( maxMIPLevel ) ;
float sigma = 3.14159265359 * roughness * roughness / ( 1.0 + roughness ) ;
float desiredMIPLevel = maxMIPLevelScalar + log2 ( sigma ) ;
return clamp ( desiredMIPLevel , 0.0 , maxMIPLevelScalar ) ;
}
vec3 getLightProbeIndirectRadiance ( const in vec3 viewDir , const in vec3 normal , const in float roughness , const in int maxMIPLevel ) {
vec3 reflectVec = reflect ( - viewDir , normal ) ;
reflectVec = normalize ( mix ( reflectVec , normal , roughness * roughness ) ) ;
reflectVec = inverseTransformDirection ( reflectVec , viewMatrix ) ;
float specularMIPLevel = getSpecularMIPLevel ( roughness , maxMIPLevel ) ;
vec2 sampleUV ;
sampleUV . y = asin ( clamp ( reflectVec . y , - 1.0 , 1.0 ) ) * 0.31830988618 + 0.5 ;
sampleUV . x = atan ( reflectVec . z , reflectVec . x ) * 0.15915494 + 0.5 ;
vec4 envMapColor = texture2D ( envMap , sampleUV , specularMIPLevel ) ;
envMapColor . rgb = envMapTexelToLinear ( envMapColor ) . rgb ;
return envMapColor . rgb * envMapIntensity ;
}
uniform bool receiveShadow ;
uniform vec3 ambientLightColor ;
uniform vec3 lightProbe [ 9 ] ;
vec3 shGetIrradianceAt ( in vec3 normal , in vec3 shCoefficients [ 9 ] ) {
float x = normal . x , y = normal . y , z = normal . z ;
vec3 result = shCoefficients [ 0 ] * 0.886227 ;
result += shCoefficients [ 1 ] * 2.0 * 0.511664 * y ;
result += shCoefficients [ 2 ] * 2.0 * 0.511664 * z ;
result += shCoefficients [ 3 ] * 2.0 * 0.511664 * x ;
result += shCoefficients [ 4 ] * 2.0 * 0.429043 * x * y ;
result += shCoefficients [ 5 ] * 2.0 * 0.429043 * y * z ;
result += shCoefficients [ 6 ] * ( 0.743125 * z * z - 0.247708 ) ;
result += shCoefficients [ 7 ] * 2.0 * 0.429043 * x * z ;
result += shCoefficients [ 8 ] * 0.429043 * ( x * x - y * y ) ;
return result ;
}
vec3 getLightProbeIrradiance ( const in vec3 lightProbe [ 9 ] , const in GeometricContext geometry ) {
vec3 worldNormal = inverseTransformDirection ( geometry . normal , viewMatrix ) ;
vec3 irradiance = shGetIrradianceAt ( worldNormal , lightProbe ) ;
return irradiance ;
}
vec3 getAmbientLightIrradiance ( const in vec3 ambientLightColor ) {
vec3 irradiance = ambientLightColor ;
irradiance *= 3.141592653589793 ;
return irradiance ;
}
struct PhysicalMaterial {
vec3 diffuseColor ;
float specularRoughness ;
vec3 specularColor ;
float clearcoat ;
float clearcoatRoughness ;
} ;
float clearcoatDHRApprox ( const in float roughness , const in float dotNL ) {
return 0.04 + ( 1.0 - 0.04 ) * ( pow ( 1.0 - dotNL , 5.0 ) * pow ( 1.0 - roughness , 2.0 ) ) ;
}
void RE_Direct_Physical ( const in IncidentLight directLight , const in GeometricContext geometry , const in PhysicalMaterial material , inout ReflectedLight reflectedLight ) {
float dotNL = clamp ( dot ( geometry . normal , directLight . direction ) , 0.0 , 1.0 ) ;
vec3 irradiance = dotNL * directLight . color ;
irradiance *= 3.141592653589793 ;
float ccDotNL = clamp ( dot ( geometry . clearcoatNormal , directLight . direction ) , 0.0 , 1.0 ) ;
vec3 ccIrradiance = ccDotNL * directLight . color ;
ccIrradiance *= 3.141592653589793 ;
float clearcoatDHR = material . clearcoat * clearcoatDHRApprox ( material . clearcoatRoughness , ccDotNL ) ;
reflectedLight . directSpecular += ccIrradiance * material . clearcoat * BRDF_Specular_GGX ( directLight , geometry . viewDir , geometry . clearcoatNormal , vec3 ( 0.04 ) , material . clearcoatRoughness ) ;
reflectedLight . directSpecular += ( 1.0 - clearcoatDHR ) * irradiance * BRDF_Specular_GGX ( directLight , geometry . viewDir , geometry . normal , material . specularColor , material . specularRoughness ) ;
reflectedLight . directDiffuse += ( 1.0 - clearcoatDHR ) * irradiance * BRDF_Diffuse_Lambert ( material . diffuseColor ) ;
}
void RE_IndirectDiffuse_Physical ( const in vec3 irradiance , const in GeometricContext geometry , const in PhysicalMaterial material , inout ReflectedLight reflectedLight ) {
reflectedLight . indirectDiffuse += irradiance * BRDF_Diffuse_Lambert ( material . diffuseColor ) ;
}
void RE_IndirectSpecular_Physical ( const in vec3 radiance , const in vec3 irradiance , const in vec3 clearcoatRadiance , const in GeometricContext geometry , const in PhysicalMaterial material , inout ReflectedLight reflectedLight ) {
float ccDotNV = clamp ( dot ( geometry . clearcoatNormal , geometry . viewDir ) , 0.0 , 1.0 ) ;
reflectedLight . indirectSpecular += clearcoatRadiance * material . clearcoat * BRDF_Specular_GGX_Environment ( geometry . viewDir , geometry . clearcoatNormal , vec3 ( 0.04 ) , material . clearcoatRoughness ) ;
float ccDotNL = ccDotNV ;
float clearcoatDHR = material . clearcoat * clearcoatDHRApprox ( material . clearcoatRoughness , ccDotNL ) ;
float clearcoatInv = 1.0 - clearcoatDHR ;
vec3 singleScattering = vec3 ( 0.0 ) ;
vec3 multiScattering = vec3 ( 0.0 ) ;
vec3 cosineWeightedIrradiance = irradiance * 0.3183098861837907 ;
BRDF_Specular_Multiscattering_Environment ( geometry , material . specularColor , material . specularRoughness , singleScattering , multiScattering ) ;
vec3 diffuse = material . diffuseColor * ( 1.0 - ( singleScattering + multiScattering ) ) ;
reflectedLight . indirectSpecular += clearcoatInv * radiance * singleScattering ;
reflectedLight . indirectSpecular += multiScattering * cosineWeightedIrradiance ;
reflectedLight . indirectDiffuse += diffuse * cosineWeightedIrradiance ;
}
float computeSpecularOcclusion ( const in float dotNV , const in float ambientOcclusion , const in float roughness ) {
return clamp ( pow ( dotNV + ambientOcclusion , exp2 ( - 16.0 * roughness - 1.0 ) ) - 1.0 + ambientOcclusion , 0.0 , 1.0 ) ;
}

uniform sampler2D alphaMap ;
uniform sampler2D normalMap ;
uniform sampler2D goldNormalNoise ;
uniform vec2 normalScale ;

uniform float parallaxScale ;

uniform float parallaxMinLayers ;
uniform float parallaxMaxLayers ;

uniform float normalScaleFactor ;
uniform float vecXNormalScale ;
uniform float vecYNormalScale ;


vec2 perturbUv( vec3 surfPosition, vec3 surfNormal, vec3 viewPosition, mat3 VTBN ) {

vec3 vViewPositionBis;
vViewPositionBis.x = dot(vViewPosition, VTBN[0]);
vViewPositionBis.y = dot(vViewPosition, VTBN[1]);
vViewPositionBis.z = dot(vViewPosition, VTBN[2]);
 vec2 texDx = dFdx( vUv );
vec2 texDy = dFdy( vUv );

vViewPositionBis = normalize(vViewPositionBis);

//const float fNumSteps = mix ( parallaxMaxLayers , parallaxMinLayers , vViewPositionBis . z ) ;
const float fNumSteps = 32.0;
float fStep = 1.0 / fNumSteps ;
vec2 vDelta = vViewPositionBis . xy * parallaxScale / fNumSteps ;

float fHeight = 1.0 ;

float elevation = texture2D ( normalMap , vUv ) . a;

vec2 vTexture = vUv;

float elevationOld = elevation ;
    for ( float i=0.0; i < fNumSteps; i++ )
    {
        fHeight -= fStep ;
        vTexture -= vDelta ;
        elevationOld = elevation ;
		//elevation = textureGrad ( normalMap , vTexture , texDx , texDy ) . a ;
		elevation = texture2D ( normalMap , vTexture ) . a ;
		if(elevation > fHeight){
			break;
		}
    }
    float h_diff = elevation - fHeight ;
    float prev_h_diff = ( fHeight + fStep ) - elevationOld ;
    float fInterpolation = prev_h_diff / ( prev_h_diff + h_diff ) ;
    return mix ( vTexture + vDelta , vTexture , fInterpolation ) ;
}
uniform sampler2D sampNoise;
float sum( vec4 v ) { return v.x+v.y+v.z; }

vec4 textureNoTile ( sampler2D samp, in vec2 uv ) 
{
    // sample variation pattern    
    float k = texture2D( sampNoise, 0.1*uv ).x; // cheap (cache friendly) lookup    
    
    // compute index    
    float index = k*8.0;
    float i = floor( index );
    float f = fract( index );

    // offsets for the different virtual patterns    
    vec2 offa = sin(vec2(3.0,7.0)*(i+0.0)); // can replace with any other hash    
    vec2 offb = sin(vec2(3.0,7.0)*(i+1.0)); // can replace with any other hash    

    // compute derivatives for mip-mapping    
    vec2 dx = dFdx(uv), dy = dFdy(uv);
    
    // sample the two closest virtual patterns    
    //vec4 cola = textureGrad( samp, uv + offa, dx, dy );
	//vec4 colb = textureGrad( samp, uv + offb, dx, dy );
	
	vec4 cola = texture2D( samp, uv + offa );
	vec4 colb = texture2D( samp, uv + offb );

    // interpolate between the two virtual patterns    
    return mix( cola, colb, smoothstep(0.2,0.8,f-0.1*sum(cola-colb)) );

}


void main() {	
#ifdef BOTTLE_SHADER
    if( vUv2.x < 0.0 ) {discard;};
    if( 1.0 - vUv2.x < 0.0 ){discard;};
    if( vUv2.y < 0.0 ) {discard;};
	if( 1.0 - vUv2.y < 0.0) {discard;};
#endif
	vec3 normal = normalize ( vNormal ) ;
	vec3 tangent = normalize ( vTangent ) ;
	vec3 bitangent = normalize ( vBitangent ) ;
	mat3 vTBN = mat3 ( tangent , bitangent , normal ) ;
	vec2 mapUv = perturbUv( -vViewPosition, normalize( vNormal ), normalize( vViewPosition ), vTBN );
	vec4 diffuseColor = vec4 ( diffuse , opacity ) ;
	ReflectedLight reflectedLight = ReflectedLight ( vec3 ( 0.0 ) , vec3 ( 0.0 ) , vec3 ( 0.0 ) , vec3 ( 0.0 ) ) ;
	vec3 totalEmissiveRadiance = emissive ;
	vec4 texelColor = textureNoTile( map, vUv );
    texelColor = mapTexelToLinear( texelColor );
    diffuseColor *= texelColor;
	float roughnessFactor = roughness ;
	float metalnessFactor = metalness ;
	vec3 geometryNormal = normal ;
	vec3 mapN = texture2D ( normalMap , vUv ) . xyz * 2.0 - 1.0 ;
	mapN . xy *= normalScale * normalScaleFactor;
	vec3 mapNBis = texture2D ( normalMap , vUv * vec2(vecXNormalScale, vecYNormalScale)) . xyz * 2.0 - 1.0 ;
	mapNBis . xy *= normalScale * normalScaleFactor;
	mapN += mapNBis;
	normal = normalize ( vTBN * mapN ) ;
	vec3 clearcoatNormal = geometryNormal ;
	PhysicalMaterial material ;
	material . diffuseColor = diffuseColor . rgb * ( 1.0 - metalnessFactor ) ;
	vec3 dxy = max ( abs ( dFdx ( geometryNormal ) ) , abs ( dFdy ( geometryNormal ) ) ) ;
	float geometryRoughness = max ( max ( dxy . x , dxy . y ) , dxy . z ) ;
	material . specularRoughness = max ( roughnessFactor , 0.0525 ) ;
	material . specularRoughness += geometryRoughness ;
	material . specularRoughness = min ( material . specularRoughness , 1.0 ) ;
	material . specularColor = mix ( vec3 ( 0.16 * pow2 ( reflectivity ) ) , diffuseColor . rgb , metalnessFactor ) ;
	material . clearcoat = clearcoat ;
	material . clearcoatRoughness = clearcoatRoughness ;
	material . clearcoat = clamp ( material . clearcoat , 0.0 , 1.0 ) ;
	material . clearcoatRoughness = max ( material . clearcoatRoughness , 0.0525 ) ;
	material . clearcoatRoughness += geometryRoughness ;
	material . clearcoatRoughness = min ( material . clearcoatRoughness , 1.0 ) ;
	GeometricContext geometry ;
	geometry . position = - vViewPosition ;
	geometry . normal = normal ;
	geometry . viewDir = ( isOrthographic ) ? vec3 ( 0 , 0 , 1 ) : normalize ( vViewPosition ) ;
	geometry . clearcoatNormal = clearcoatNormal ;
	IncidentLight directLight ;
	vec3 iblIrradiance = vec3 ( 0.0 ) ;
	vec3 irradiance = getAmbientLightIrradiance ( ambientLightColor ) ;
	irradiance += getLightProbeIrradiance ( lightProbe , geometry ) ;
	vec3 radiance = vec3 ( 0.0 ) ;
	vec3 clearcoatRadiance = vec3 ( 0.0 ) ;
	radiance += getLightProbeIndirectRadiance ( geometry . viewDir , geometry . normal , material . specularRoughness , maxMipLevel ) ;
	clearcoatRadiance += getLightProbeIndirectRadiance ( geometry . viewDir , geometry . clearcoatNormal , material . clearcoatRoughness , maxMipLevel ) ;
	RE_IndirectDiffuse_Physical ( irradiance , geometry , material , reflectedLight ) ;
	RE_IndirectSpecular_Physical ( radiance , iblIrradiance , clearcoatRadiance , geometry , material , reflectedLight ) ;
	vec3 outgoingLight = reflectedLight . directDiffuse + reflectedLight . indirectDiffuse + reflectedLight . directSpecular + reflectedLight . indirectSpecular + totalEmissiveRadiance ;
	diffuseColor . a *= clamp ( 1. - transparency + linearToRelativeLuminance ( reflectedLight . directSpecular + reflectedLight . indirectSpecular ) , 0.0 , 1.0 ) ;
	gl_FragColor = vec4 ( outgoingLight, texelColor . a ) ;	
	//gl_FragColor = vec4 (texture2D ( normalMap , vUv ) . aaa , 1.0);
	gl_FragColor = linearToOutputTexel ( gl_FragColor ) ;
	
	gl_FragColor . a *= texture2D( alphaMap, vUv2 ) . r;

	#ifdef BOTTLE_SHADER
if (gl_FragColor . a <= 0.1 ) {discard;}
#endif
	
	//gl_FragColor . a = 0;
}`;
