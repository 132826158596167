import React, { useMemo } from "react";
import { animated, SpringValue } from "react-spring";
import { Color, PlaneBufferGeometry, Texture, Vector2, Vector4 } from "three";
import { FaceGeometry, layerIsEnabled, LayerView } from "../../Domain";
import { useWhiteTexture } from "../../hooks/WhiteTexture";
import { PaperMaterial } from "../PreviewScene";
import { paperFragmentShader } from "../Shaders/paperFragmentShader";
import { paperVertexShader } from "../Shaders/paperVertexShader";

type PaperProps = {
  geometry: PlaneBufferGeometry;
  faceGeometry: FaceGeometry;
  paper: Texture | undefined;
  sampNoise?: Texture;
  animatedOpacity: SpringValue<number>;
  faceName: string;
  layersView: LayerView[];
  faceUVs: { repeat: Vector2; offset: Vector2 };
  envMap?: Texture;
  activePaper: PaperMaterial;
  normalNoise?: Texture;
  mask?: Texture;
};

const Paper = ({
  geometry,
  faceGeometry,
  paper,
  sampNoise,
  animatedOpacity,
  faceName,
  layersView,
  faceUVs,
  envMap,
  activePaper,
  normalNoise,
  mask,
}: PaperProps) => {
  const white = useWhiteTexture();

  const repeat = faceUVs.repeat.clone();
  repeat.multiplyScalar(8);

  const repeat2 = faceUVs.repeat.clone();

  const offset = faceUVs.offset.clone();
  //offset.multiplyScalar(8);

  const paperUniformsRecto = useMemo(() => {
    return {
      diffuse: {
        value: new Color(1, 1, 1),
      },
      opacity: { value: animatedOpacity },
      map: { value: paper },
      uvTransform: {
        value: [repeat.x, -0, 0, 0, repeat.y, 0, offset.x, offset.y, 1],
      },
      uv2Transform: {
        value: [repeat2.x, -0, 0, 0, repeat2.y, 0, offset.x, offset.y, 1],
      },
      alphaMap: { value: mask ?? white },
      envMap: { value: envMap },
      flipEnvMap: { value: -1 },
      reflectivity: { value: 1 },
      refractionRatio: { value: 0.98 },
      maxMipLevel: { value: 0 },
      aoMap: { value: null },
      aoMapIntensity: { value: 1 },
      lightMap: { value: null },
      lightMapIntensity: { value: 1 },
      emissiveMap: { value: null },
      bumpMap: { value: null },
      bumpScale: { value: 1 },
      normalMap: { value: normalNoise },
      normalScale: { value: { x: 1, y: 1 } },
      roughnessMap: { value: null },
      metalnessMap: { value: null },
      ambientLightColor: {
        value: [0.9, 0.9, 0.9],
        needsUpdate: true,
      },
      emissive: {
        value: activePaper.emissive
          ? new Color(
              activePaper.emissive,
              activePaper.emissive,
              activePaper.emissive
            )
          : new Color(0, 0, 0),
      },
      roughness: { value: activePaper.roughness },
      metalness: { value: 0 },
      envMapIntensity: { value: activePaper.envMapIntensity },
      transparency: { value: 1 },
      clearcoat: { value: 0 },
      clearcoatRoughness: { value: 1 },
      sheen: { value: new Color(0, 0, 0) },
      clearcoatNormalScale: { value: { x: 1, y: 1 } },
      clearcoatNormalMap: { value: null },
      clippingPlanes: { value: null, needsUpdate: false },
      sampNoise: { value: sampNoise },
      normalScaleFactor: { value: activePaper.normalScaleFactor },
      vecXNormalScale: { value: activePaper.vecNormalScale },
      vecYNormalScale: { value: activePaper.vecNormalScale },
      parallaxScale: { value: 0 },
      parallaxMinLayers: { value: 0 },
      parallaxMaxLayers: { value: 0 },
      flip: { value: new Vector4(0, 1, 0, 1) },
    };
  }, []);

  const paperUniformsVerso = {
    ...paperUniformsRecto,
    flip: { value: new Vector4(1, -1, 0, 1) },
  };

  return (
    <>
      <mesh
        name={faceName}
        geometry={geometry}
        position={[faceGeometry.width / 2, -faceGeometry.height / 2, 1]}
        visible={layerIsEnabled("paper", layersView)}
      >
        <animated.shaderMaterial
          attach="material"
          uniforms={paperUniformsRecto}
          vertexShader={paperVertexShader}
          fragmentShader={paperFragmentShader}
          transparent
        />
      </mesh>
      <mesh
        name={faceName}
        geometry={geometry}
        position={[faceGeometry.width / 2, -faceGeometry.height / 2, 1]}
        rotation={[0, Math.PI, 0]}
        visible={layerIsEnabled("paper", layersView)}
      >
        <animated.shaderMaterial
          attach="material"
          uniforms={paperUniformsVerso}
          vertexShader={paperVertexShader}
          fragmentShader={paperFragmentShader}
          transparent
        />
      </mesh>
    </>
  );
};
export default Paper;
