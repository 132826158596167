import React, { Dispatch, Suspense, useEffect, useMemo, useState } from "react";
import { useThree, useUpdate } from "react-three-fiber";
import {
  BackSide,
  Box3,
  CubeReflectionMapping,
  Group,
  MeshBasicMaterial,
  SphereBufferGeometry,
  Texture,
  TextureLoader,
  Vector3,
} from "three";
import {
  Coords3D,
  Dieline,
  findIntersections,
  SmartLabelIntersection,
} from "../Domain";
import { Annotations } from "../Domain/Annotations";
import { FoilMapping } from "../Domain/Foil";
import { Bottle } from "./Bottle";
import { VarnishMaterialProps } from "./Layers/Varnish";
import { LedDisplayProps } from "./Leds";
import { PackagingFace3D } from "./PackagingFace3D";
import {
  computeRectoAndVersoNormalMap,
  initPackagingTextureCache,
  PackagingTextureCache,
} from "./PackagingTextureCache";
import { CameraProps, CreationState, PaperMaterial } from "./PreviewScene";

const AXES_HELPER_SIZE = 300;

type FoldingPackagingProps = {
  creationState: CreationState;
  dieline: Dieline;
  enableAxesHelper: boolean;
  rotationFactor: number;
  opacity: number;
  ledProps: LedDisplayProps;
  folded: boolean;
  updateCamera: Dispatch<CameraProps>;
  onNewAnnotation?: React.Dispatch<Annotations>;
  cancelNewAnnotation?: React.Dispatch<void>;
  varnishMaterialProps: VarnishMaterialProps;
  useBluredHeightMap: boolean;
  displayedMap: string;
  spriteMaterialMode: boolean;
  annotationSize: number;
  activePaper: PaperMaterial;
  annotationMode: "display" | "pendingPlacement" | "pendingValidation";
  handleDeleteEvent?: React.Dispatch<Annotations>;
  onSelect3DAnnotation?: React.Dispatch<Annotations>;
  active360Background: string | undefined;
  foilMapping: FoilMapping;
  foilDiffuseColor: string;
  showOnBottle: boolean;
};
export function Packaging3D({
  creationState,
  dieline: { foldingTree },
  enableAxesHelper,
  rotationFactor,
  opacity,
  ledProps,
  folded,
  updateCamera,
  onNewAnnotation,
  cancelNewAnnotation,
  varnishMaterialProps,
  useBluredHeightMap,
  displayedMap,
  spriteMaterialMode,
  annotationSize,
  activePaper,
  annotationMode,
  handleDeleteEvent,
  onSelect3DAnnotation,
  active360Background,
  foilMapping,
  foilDiffuseColor,
  showOnBottle,
}: FoldingPackagingProps) {
  const { gltfMode } = creationState;

  const smartLabelIntersections: SmartLabelIntersection[] = useMemo(
    () =>
      (creationState.smartLabels ?? []).flatMap((smartLabel) =>
        findIntersections(smartLabel, creationState.packaging.dieline)
      ),
    [creationState.packaging.dieline, creationState.smartLabels]
  );

  // Load design image into a shared packaging texture

  const [textureCache, setTextureCache] = useState<PackagingTextureCache>();

  const { gl } = useThree();

  // Maybe extract this effect into a custom hook for texture cache loading/disposing

  useEffect(() => {
    async function loadAndCropLayers() {
      // Unload previous textures as soon as we switch to a different packaging
      // (while the next packaging textures are being loaded)
      if (creationState.packaging.paper) {
        creationState.packaging.paper.layer.url = activePaper.name + ".png";
      }
      setTextureCache(undefined);
      let newTextureCache = await initPackagingTextureCache(
        creationState.packaging,
        active360Background
      );
      if (newTextureCache) {
        newTextureCache = await computeRectoAndVersoNormalMap(
          gl,
          newTextureCache,
          useBluredHeightMap
        );
      }
      setTextureCache(newTextureCache);
    }
    loadAndCropLayers();
    return () => {
      if (textureCache?.recto) {
        Object.values(textureCache?.recto).forEach((texture) => {
          texture?.dispose();
        });
      }
      if (textureCache?.verso) {
        Object.values(textureCache?.verso).forEach((texture) => {
          texture?.dispose();
        });
      }
      if (textureCache?.globalTextures) {
        Object.values(textureCache?.globalTextures).forEach((texture) => {
          texture?.dispose();
        });
      }
    };
  }, [
    creationState.packaging,
    useBluredHeightMap,
    activePaper,
    active360Background,
  ]);

  const defaultEnvMap = useMemo(() => {
    const map = new TextureLoader().load(
      `${process.env.PUBLIC_URL}/envMap/defaultEnvMap.png`
    );
    map.mapping = CubeReflectionMapping;
    return map;
  }, []);

  const envMap: Texture | undefined = textureCache?.globalTextures.envMap;
  const envMapBlured: Texture | undefined =
    textureCache?.globalTextures.envMapBlured;
  // Skybox

  // Set camera target to center of packaging box
  const groupRef = useUpdate<Group>(
    (group) => {
      if (group) {
        // Compute bounding box using Box3
        // Inspired by setContent() method in three-gltf-viewer:
        //   https://github.com/donmccurdy/three-gltf-viewer/blob/master/src/viewer.js
        const box = new Box3().setFromObject(group);

        // Compute camera target and position
        const center = box.getCenter(new Vector3());
        const size = group.localToWorld(box.getSize(new Vector3())).length();
        const position: Coords3D = [
          center.x + size / 2.0,
          center.y + size * 0.2,
          center.z + size * 0.6,
        ];
        if (gltfMode) {
          group.position.set(-center.x, -center.y, -center.z);
        }

        updateCamera({
          position: position,
          packagingSize: size,
          target: gltfMode ? undefined : (center.toArray() as Coords3D),
        });
      }
    },
    [creationState.packaging, gltfMode, showOnBottle]
  );

  return (
    <>
      {
        // Code to display skybox
        active360Background && envMap && (
          <mesh
            material={new MeshBasicMaterial({ side: BackSide, map: envMap })}
            geometry={new SphereBufferGeometry(100000, 32, 32)}
            renderOrder={2000}
            position={[0, 0, 0]}
            rotation={[0, Math.PI, 0]}
            scale={[-1, 1, 1]}
          />
        )
      }
      {creationState.packaging.dieline.name === "flat" && showOnBottle ? (
        <Suspense fallback={"...Chargement"}>
          <group ref={groupRef} rotation={creationState.packaging.rotation}>
            {
              <Bottle
                textureCache={textureCache}
                creationState={creationState}
                layersView={creationState.layersView}
                dimensions={creationState.packaging.dieline.dimensions}
                activePaper={activePaper}
                updateCamera={updateCamera}
                foilMapping={foilMapping}
              />
            }
          </group>
        </Suspense>
      ) : (
        <group ref={groupRef} rotation={creationState.packaging.rotation}>
          {enableAxesHelper && <axesHelper args={[AXES_HELPER_SIZE]} />}

          {
            <PackagingFace3D
              onNewAnnotation={onNewAnnotation}
              node={foldingTree}
              creationState={creationState}
              origin={[0, 0, 0]}
              enableAxesHelper={enableAxesHelper}
              rotationFactor={rotationFactor}
              opacity={opacity}
              ledProps={ledProps}
              smartLabelIntersections={smartLabelIntersections}
              folded={folded}
              textureCache={textureCache}
              envMap={envMapBlured ?? defaultEnvMap}
              varnishMaterialProps={varnishMaterialProps}
              displayedMap={displayedMap}
              spriteMaterialMode={spriteMaterialMode}
              annotationSize={annotationSize}
              cancelNewAnnotation={cancelNewAnnotation}
              activePaperName={activePaper}
              annotationMode={annotationMode}
              handleDeleteEvent={handleDeleteEvent}
              onSelect3DAnnotation={onSelect3DAnnotation}
              foilMapping={foilMapping}
              foilDiffuseColor={foilDiffuseColor}
            />
          }
        </group>
      )}
    </>
  );
}
