import { Color } from "three";

export type FoilMaterial = {
  name: string;
  diffuse: Color;
  emissive: Color;
  roughness: number;
  metalness: number;
  reflectivity: number;
  envMapIntensity: number;
  normalScaleFactor: number;
  vecXNormalScale: number;
  vecYNormalScale: number;
};

// Map foil layers to specific foil materials
// Example:
//   Show a gold foil material for the silver foil layer like this
//   <PreviewScene foilMapping={{'silverFoil': goldFoilMaterial }} ... /> */
//
export interface FoilMapping {
  [foilLayerName: string]: FoilMaterial;
}

export const goldFoilMaterial = {
  name: "goldFoil",
  diffuse: new Color(1.1, 0.8, 0.5), //gold
  emissive: new Color(0, 0, 0),
  roughness: 0.25,
  metalness: 0.55,
  reflectivity: 1.0,
  envMapIntensity: 2.0,
  normalScaleFactor: 0.035,
  vecXNormalScale: 6.0,
  vecYNormalScale: 6.0,
};

export const silverFoilMaterial = {
  name: "silverFoil",
  diffuse: new Color(0.8, 0.8, 0.8), //silver
  emissive: new Color(0, 0, 0),
  roughness: 0.25,
  metalness: 0.55,
  reflectivity: 1.0,
  envMapIntensity: 2.0,
  normalScaleFactor: 0.035,
  vecXNormalScale: 6.0,
  vecYNormalScale: 6.0,
};

export const copperFoilMaterial = {
  name: "copperFoil",
  diffuse: new Color(0.68, 0.38, 0.33), //copper,
  emissive: new Color(0, 0, 0),
  roughness: 0.25,
  metalness: 0.55,
  reflectivity: 1.0,
  envMapIntensity: 2.0,
  normalScaleFactor: 0.035,
  vecXNormalScale: 6.0,
  vecYNormalScale: 6.0,
};

export const redFoilMaterial = {
  name: "redFoil",
  diffuse: new Color(1, 0.05, 0.05), //red
  emissive: new Color(0, 0, 0),
  roughness: 0.25,
  metalness: 0.55,
  reflectivity: 1.0,
  envMapIntensity: 2.0,
  normalScaleFactor: 0.07,
  vecXNormalScale: 6.0,
  vecYNormalScale: 6.0,
};

export const greenFoilMaterial = {
  name: "greenFoil",
  diffuse: new Color(0, 0.7, 0.2), //green
  emissive: new Color(0, 0, 0),
  roughness: 0.25,
  metalness: 0.55,
  reflectivity: 1.0,
  envMapIntensity: 2.0,
  normalScaleFactor: 0.07,
  vecXNormalScale: 6.0,
  vecYNormalScale: 6.0,
};

export const blueFoilMaterial = {
  name: "blueFoil",
  diffuse: new Color(0.1, 0.2, 1), //blue
  emissive: new Color(0, 0, 0),
  roughness: 0.25,
  metalness: 0.55,
  reflectivity: 1.0,
  envMapIntensity: 2.0,
  normalScaleFactor: 0.07,
  vecXNormalScale: 6.0,
  vecYNormalScale: 6.0,
};
export const pinkFoilMaterial = {
  name: "pinkFoil",
  diffuse: new Color(1, 0.2, 0.9), //pink
  emissive: new Color(0, 0, 0),
  roughness: 0.25,
  metalness: 0.55,
  reflectivity: 1.0,
  envMapIntensity: 2.0,
  normalScaleFactor: 0.07,
  vecXNormalScale: 6.0,
  vecYNormalScale: 6.0,
};
export const orangeFoilMaterial = {
  name: "orangeFoil",
  diffuse: new Color(1, 0.4, 0.05), //orange
  emissive: new Color(0, 0, 0),
  roughness: 0.25,
  metalness: 0.55,
  reflectivity: 1.0,
  envMapIntensity: 2.0,
  normalScaleFactor: 0.07,
  vecXNormalScale: 6.0,
  vecYNormalScale: 6.0,
};
export const blackFoilMaterial = {
  name: "blackFoil",
  diffuse: new Color(0.05, 0.05, 0.05), //black
  emissive: new Color(0, 0, 0),
  roughness: 0.25,
  metalness: 0.55,
  reflectivity: 1.0,
  envMapIntensity: 2.0,
  normalScaleFactor: 0.07,
  vecXNormalScale: 6.0,
  vecYNormalScale: 6.0,
};

export const defaultFoilMapping: FoilMapping = {
  goldFoil: goldFoilMaterial,
  silverFoil: silverFoilMaterial,
  copperFoil: copperFoilMaterial,
  redFoil: redFoilMaterial,
  greenFoil: greenFoilMaterial,
  blueFoil: blueFoilMaterial,
  pinkFoil: pinkFoilMaterial,
  orangeFoil: orangeFoilMaterial,
  blackFoil: blackFoilMaterial,
};


export function mapFoilNameToFoilMaterial(name: string){
  switch(name){
    case "goldFoil":
      return goldFoilMaterial;
    case "silverFoil":
      return silverFoilMaterial;
    case "copperFoil":
      return copperFoilMaterial
    case "redFoil":
      return redFoilMaterial
    case "greenFoil":
      return greenFoilMaterial
    case "blueFoil":
      return blueFoilMaterial
    case "pinkFoil":
      return pinkFoilMaterial
    case "orangeFoil":
      return orangeFoilMaterial
    case "blackFoil":
      return blackFoilMaterial;
    default: 
      return goldFoilMaterial;
  }
}