import { Dieline } from "./Dieline";
import { Face, FaceGeometry } from "./Face";
import { SmartLabelEntity } from "./SmartLabelEntity";

export default SmartLabel;

export type SmartLabel = {
  key: number;
  entity: SmartLabelEntity;
  width: number;
  height: number;
  props2D: {
    top: number;
    left: number;
    // Center coordinates
    x: number;
    y: number;
  };
  face?: {
    faceName: string;
    geometry: FaceGeometry;
  };

  // Useful to hide LEDs when smart label is being moved
  hideLEDs?: boolean;
  // To know which smartLabel is selected
  active?: boolean;
};

// An intersection is the rectangular subset of a smart label that is overlapping with a given
// packaging face
export type SmartLabelIntersection = {
  smartLabel: SmartLabel;
  faceName: string;
  top: number;
  left: number;
  x: number;
  y: number;
  offset: {
    x: number;
    y: number;
  };
  width: number;
  height: number;

  // Led positions in the 3D local space of the intersection (origin is the center
  // of the intersection plane)
  ledPositions: Array<[number, number] | undefined>;
};

type InitSmartLabelArgs = {
  key: number;
  entity: SmartLabelEntity;
  top: number;
  left: number;
  face?: Face;
  hideLEDs?: boolean;
  active?: boolean;
};
export function initSmartLabel({
  key,
  entity,
  top,
  left,
  face,
  hideLEDs,
  active,
}: InitSmartLabelArgs): SmartLabel {
  const width = entity.width * entity.scale;
  const height = entity.height * entity.scale;
  const x = left + width / 2;
  const y = top + height / 2;
  return {
    key,
    entity,
    width,
    height,
    props2D: { top, left, x, y },
    face,
    active,
    hideLEDs: hideLEDs ?? false,
  };
}

export function findIntersectingFaces(
  smartLabel: SmartLabel,
  dieline: Dieline
): string[] {
  return Object.entries(dieline.faceGeometries)
    .filter(([faceName, geometry]) =>
      computeIntersection(smartLabel, {
        faceName,
        geometry,
      })
    )
    .map(([faceName]) => faceName);
}

export function findIntersections(
  smartLabel: SmartLabel,
  dieline: Dieline
): SmartLabelIntersection[] {
  function isDefined<T>(t: T | undefined): t is T {
    return typeof t !== "undefined";
  }
  return Object.entries(dieline.faceGeometries)
    .map(([faceName, geometry]) =>
      computeIntersection(smartLabel, {
        faceName,
        geometry,
      })
    )
    .filter(isDefined);
}

function computeIntersection(
  smartLabel: SmartLabel,
  face: Face
): SmartLabelIntersection | undefined {
  const { faceName, geometry } = face;
  const {
    entity,
    props2D: { top, left },
    width,
    height,
  } = smartLabel;
  const upperLeft = { x: left, y: top };
  const lowerRight = {
    x: left + width,
    y: top + height,
  };
  if (
    lowerRight.x >= geometry.x &&
    lowerRight.y >= geometry.y &&
    upperLeft.x <= geometry.x + geometry.width &&
    upperLeft.y <= geometry.y + geometry.height
  ) {
    const newLeft = Math.max(upperLeft.x, geometry.x);
    const newTop = Math.max(upperLeft.y, geometry.y);
    const newLowerRight = {
      x: Math.min(lowerRight.x, geometry.x + geometry.width),
      y: Math.min(lowerRight.y, geometry.y + geometry.height),
    };
    const newWidth = newLowerRight.x - newLeft;
    const newHeight = newLowerRight.y - newTop;
    const offsetX = newLeft - left;
    const offsetY = newTop - top;
    return {
      smartLabel,
      faceName,
      left: newLeft,
      top: newTop,
      width: newWidth,
      height: newHeight,
      x: newLeft + newWidth / 2,
      y: newTop + newHeight / 2,
      offset: { x: offsetX, y: offsetY },
      ledPositions: entity.leds.map(([x, y]) => {
        const led2dX = x * entity.scale - offsetX;
        const led2dY = y * entity.scale - offsetY;
        const isInIntersection: boolean =
          led2dX >= 0 &&
          led2dX <= newWidth &&
          led2dY >= 0 &&
          led2dY <= newHeight;
        const led3dX = led2dX - newWidth / 2;
        const led3dY = -(
          offsetY +
          newHeight -
          y * entity.scale -
          newHeight / 2
        );
        return isInIntersection ? [led3dX, led3dY] : undefined;
      }),
    };
  } else {
    return undefined;
  }
}
