import React, { Dispatch, useEffect, useMemo, useRef } from "react";
import { useLoader } from "react-three-fiber";
import {
  Box3,
  BufferGeometry,
  Color,
  DoubleSide,
  EquirectangularReflectionMapping,
  Group,
  LessDepth,
  Mesh,
  MeshPhongMaterial,
  ShaderMaterial,
  Vector3,
  Vector4,
} from "three";
import { OBJLoader2 } from "three/examples/jsm/loaders/OBJLoader2";
import { FoilMapping, FoilMaterial } from "../Domain/Foil";
import { useWhiteTexture } from "../hooks/WhiteTexture";
import { computeFaceUVs, PackagingTextureCache } from "./PackagingTextureCache";
import { bottleVertexShader } from "./Shaders/bottleVertexShader";
import { BufferGeometryUtils } from "three/examples/jsm/utils/BufferGeometryUtils";
import { fragmentShaderNormal } from "./Shaders/fragmentShaderNormal";
import { varnishFragmentShader } from "./Shaders/varnishFragmentShader";
import { colorFragmentShader } from "./Shaders/colorFragmentShader";
import { Coords3D, layerIsEnabled, LayerView } from "../Domain";
import { CameraProps, CreationState, PaperMaterial } from "./PreviewScene";
import { paperFragmentShader } from "./Shaders/paperFragmentShader";

export function Bottle({
  textureCache,
  layersView,
  dimensions,
  creationState,
  activePaper,
  updateCamera,
  foilMapping,
}: {
  textureCache: PackagingTextureCache | undefined;
  layersView: LayerView[];
  dimensions: {
    width: number;
    height: number;
  };
  creationState: CreationState;
  activePaper: PaperMaterial;
  updateCamera: Dispatch<CameraProps>;
  foilMapping: FoilMapping;
}) {
  const parallaxScale = 0.001;

  const parallaxMinLayers = 32.0;
  const parallaxMaxLayers = 64.0;

  const white = useWhiteTexture();

  const faceUVs = useMemo(() => {
    return computeFaceUVs(creationState.packaging, "front");
  }, [creationState.packaging]);

  const repeat = faceUVs.repeat.clone();
  repeat.multiplyScalar(8);

  const groupRef = useRef<Group>();

  useEffect(() => {
    if (groupRef.current) {
      // Compute bounding box using Box3
      // Inspired by setContent() method in three-gltf-viewer:
      //   https://github.com/donmccurdy/three-gltf-viewer/blob/master/src/viewer.js
      const box = new Box3().setFromObject(groupRef.current);

      // Compute camera target and position
      const center = box.getCenter(new Vector3());
      const size = groupRef.current
        .localToWorld(box.getSize(new Vector3()))
        .length();
      const position: Coords3D = [
        center.x + size / 2.0,
        center.y + size * 0.2,
        center.z + size * 0.6,
      ];

      updateCamera({
        position: position,
        packagingSize: size,
        target: center.toArray() as Coords3D,
      });
    }
  }, [creationState.packaging]);

  const uniforms = useMemo(() => {
    return {
      diffuse: { value: new Color(1, 1, 1) },
      opacity: { value: 1 },
      map: { value: textureCache?.recto.color },
      uvTransform: {
        value: [
          faceUVs.repeat.x,
          -0,
          0,
          0,
          faceUVs.repeat.y,
          0,
          faceUVs.offset.x,
          faceUVs.offset.y,
          1,
        ],
      },
      uv2Transform: { value: [1, 0, 0, 0, 1, 0, 0, 0, 1] },
      alphaMap: { value: textureCache?.globalTextures.mask ?? white },
      envMap: { value: /*textureCache?.globalTextures.envMap*/ null },
      flipEnvMap: { value: -1 },
      reflectivity: { value: 1 },
      refractionRatio: { value: 0.98 },
      maxMipLevel: { value: 0 },
      aoMap: { value: null },
      aoMapIntensity: { value: 1 },
      lightMap: { value: null },
      lightMapIntensity: { value: 1 },
      emissiveMap: { value: null },
      bumpMap: { value: null },
      bumpScale: { value: 1 },
      normalMap: { value: null },
      normalScale: { value: { x: 1, y: 1 } },
      displacementMap: { value: null },
      displacementScale: { value: 1 },
      displacementBias: { value: 0 },
      roughnessMap: { value: null },
      metalnessMap: { value: null },
      ambientLightColor: {
        value: [1, 1, 1],
        needsUpdate: true,
      },
      emissive: {
        value: new Color(0, 0, 0),
      },
      envMapIntensity: { value: 1 },
      transparency: { value: 1 },
      transparent: { value: true },
      labelHeight: { value: dimensions.height },
      labelWidth: { value: dimensions.width },
      flip: { value: new Vector4(0, 1, 0, 1) },
    };
  }, [textureCache]);

  const paperUniformsRecto = useMemo(() => {
    return {
      diffuse: {
        value: new Color(1, 1, 1),
      },
      opacity: { value: 1 },
      map: { value: textureCache?.globalTextures.paper },
      uvTransform: {
        value: [
          repeat.x,
          -0,
          0,
          0,
          repeat.y,
          0,
          faceUVs.offset.x,
          faceUVs.offset.y,
          1,
        ],
      },
      uv2Transform: {
        value: [
          faceUVs.repeat.x,
          -0,
          0,
          0,
          faceUVs.repeat.y,
          0,
          faceUVs.offset.x,
          faceUVs.offset.y,
          1,
        ],
      },
      alphaMap: { value: textureCache?.globalTextures.mask ?? white },
      envMap: { value: textureCache?.globalTextures.envMapBlured },
      flipEnvMap: { value: -1 },
      reflectivity: { value: 1 },
      refractionRatio: { value: 0.98 },
      maxMipLevel: { value: 0 },
      aoMap: { value: null },
      aoMapIntensity: { value: 1 },
      lightMap: { value: null },
      lightMapIntensity: { value: 1 },
      emissiveMap: { value: null },
      bumpMap: { value: null },
      bumpScale: { value: 1 },
      normalMap: { value: textureCache?.globalTextures.normalNoise },
      normalScale: { value: { x: 1, y: 1 } },
      roughnessMap: { value: null },
      metalnessMap: { value: null },
      ambientLightColor: {
        value: [0.9, 0.9, 0.9],
        needsUpdate: true,
      },
      emissive: {
        value: activePaper.emissive
          ? new Color(
              activePaper.emissive,
              activePaper.emissive,
              activePaper.emissive
            )
          : new Color(0, 0, 0),
      },
      roughness: { value: activePaper.roughness },
      metalness: { value: 0 },
      envMapIntensity: { value: activePaper.envMapIntensity },
      transparency: { value: 1 },
      clearcoat: { value: 0 },
      clearcoatRoughness: { value: 1 },
      sheen: { value: new Color(0, 0, 0) },
      clearcoatNormalScale: { value: { x: 1, y: 1 } },
      clearcoatNormalMap: { value: null },
      clippingPlanes: { value: null, needsUpdate: false },
      sampNoise: { value: textureCache?.globalTextures.sampNoise },
      normalScaleFactor: { value: activePaper.normalScaleFactor },
      vecXNormalScale: { value: activePaper.vecNormalScale },
      vecYNormalScale: { value: activePaper.vecNormalScale },
      parallaxScale: { value: 0 },
      parallaxMinLayers: { value: 0 },
      parallaxMaxLayers: { value: 0 },
      labelHeight: { value: dimensions.height },
      labelWidth: { value: dimensions.width },
      flip: { value: new Vector4(0, 1, 0, 1) },
    };
  }, [textureCache, activePaper]);

  const goldFoilMaterial: FoilMaterial = foilMapping.goldFoil;
  const silverFoilMaterial: FoilMaterial = foilMapping.silverFoil;

  if (textureCache?.globalTextures.envMapBlured)
    textureCache.globalTextures.envMapBlured.mapping = EquirectangularReflectionMapping;

  const goldFoilUniform = useMemo(() => {
    return {
      diffuse: { value: goldFoilMaterial.diffuse },
      opacity: { value: 1 },
      map: { value: textureCache?.recto.goldFoil },
      uvTransform: {
        value: [
          faceUVs?.repeat.x,
          -0,
          0,
          0,
          faceUVs?.repeat.y,
          0,
          faceUVs?.offset.x,
          faceUVs?.offset.y,
          1,
        ],
      },
      uv2Transform: { value: [1, 0, 0, 0, 1, 0, 0, 0, 1] },
      alphaMap: { value: textureCache?.globalTextures.mask ?? white },
      envMap: { value: textureCache?.globalTextures.envMapBlured },
      flipEnvMap: { value: -1 },
      reflectivity: { value: goldFoilMaterial.reflectivity },
      refractionRatio: { value: 0.98 },
      maxMipLevel: { value: 0 },
      aoMap: { value: null },
      aoMapIntensity: { value: 1 },
      lightMap: { value: null },
      lightMapIntensity: { value: 1 },
      normalMap: { value: textureCache?.recto.normalMapRenderTarget?.texture },
      normalScale: { value: { x: 1, y: 1 } },
      roughnessMap: { value: null },
      metalnessMap: { value: null },
      ambientLightColor: {
        value: [0.7278431372549019, 0.7278431372549019, 0.7278431372549019],
        needsUpdate: true,
      },
      emissive: {
        value: goldFoilMaterial.emissive,
      },
      roughness: { value: goldFoilMaterial.roughness },
      metalness: { value: goldFoilMaterial.metalness },
      envMapIntensity: { value: goldFoilMaterial.envMapIntensity },
      transparency: { value: 1 },
      clearcoat: { value: 0 },
      clearcoatRoughness: { value: 0 },
      sheen: { value: new Color(0, 0, 0) },
      clearcoatNormalScale: { value: { x: 1, y: 1 } },
      clearcoatNormalMap: { value: null },
      clippingPlanes: { value: null, needsUpdate: false },
      parallaxScale: { value: parallaxScale },
      parallaxMinLayers: { value: parallaxMinLayers },
      parallaxMaxLayers: { value: parallaxMaxLayers },
      normalNoise: { value: textureCache?.globalTextures.foilNormalNoise },
      normalScaleFactor: { value: goldFoilMaterial.normalScaleFactor },
      vecXNormalScale: { value: goldFoilMaterial.vecXNormalScale },
      vecYNormalScale: { value: goldFoilMaterial.vecYNormalScale },
      flip: { value: new Vector4(0, 1, 0, 1) },
      labelHeight: { value: dimensions.height },
      labelWidth: { value: dimensions.width },
    };
  }, [textureCache, foilMapping]);

  const silverFoilUniform = useMemo(() => {
    return {
      diffuse: { value: silverFoilMaterial.diffuse },
      opacity: { value: 1 },
      map: { value: textureCache?.recto.silverFoil },
      uvTransform: {
        value: [
          faceUVs?.repeat.x,
          -0,
          0,
          0,
          faceUVs?.repeat.y,
          0,
          faceUVs?.offset.x,
          faceUVs?.offset.y,
          1,
        ],
      },
      uv2Transform: { value: [1, 0, 0, 0, 1, 0, 0, 0, 1] },
      alphaMap: { value: textureCache?.globalTextures.mask ?? white },
      envMap: { value: textureCache?.globalTextures.envMapBlured },
      flipEnvMap: { value: -1 },
      reflectivity: { value: silverFoilMaterial.reflectivity },
      refractionRatio: { value: 0.98 },
      maxMipLevel: { value: 0 },
      aoMap: { value: null },
      aoMapIntensity: { value: 1 },
      lightMap: { value: null },
      lightMapIntensity: { value: 1 },
      normalMap: { value: textureCache?.recto.normalMapRenderTarget?.texture },
      normalScale: { value: { x: 1, y: 1 } },
      roughnessMap: { value: null },
      metalnessMap: { value: null },
      ambientLightColor: {
        value: [0.7278431372549019, 0.7278431372549019, 0.7278431372549019],
        needsUpdate: true,
      },
      emissive: {
        value: silverFoilMaterial.emissive,
      },
      roughness: { value: silverFoilMaterial.roughness },
      metalness: { value: silverFoilMaterial.metalness },
      envMapIntensity: { value: silverFoilMaterial.envMapIntensity },
      transparency: { value: 1 },
      clearcoat: { value: 0 },
      clearcoatRoughness: { value: 0 },
      sheen: { value: new Color(0, 0, 0) },
      clearcoatNormalScale: { value: { x: 1, y: 1 } },
      clearcoatNormalMap: { value: null },
      clippingPlanes: { value: null, needsUpdate: false },
      parallaxScale: { value: parallaxScale },
      parallaxMinLayers: { value: parallaxMinLayers },
      parallaxMaxLayers: { value: parallaxMaxLayers },
      normalNoise: { value: textureCache?.globalTextures.foilNormalNoise },
      normalScaleFactor: { value: silverFoilMaterial.normalScaleFactor },
      vecXNormalScale: { value: silverFoilMaterial.vecXNormalScale },
      vecYNormalScale: { value: silverFoilMaterial.vecYNormalScale },
      flip: { value: new Vector4(0, 1, 0, 1) },
      labelHeight: { value: dimensions.height },
      labelWidth: { value: dimensions.width },
    };
  }, [textureCache, foilMapping]);

  const varnishUniformsRecto = useMemo(() => {
    return {
      diffuse: {
        value: new Color(1, 1, 1),
      },
      opacity: { value: 1 },
      map: { value: textureCache?.recto.varnish },
      uvTransform: {
        value: [
          faceUVs.repeat.x,
          -0,
          0,
          0,
          faceUVs.repeat.y,
          0,
          faceUVs.offset.x,
          faceUVs.offset.y,
          1,
        ],
      },
      uv2Transform: { value: [1, 0, 0, 0, 1, 0, 0, 0, 1] },
      alphaMap: { value: textureCache?.globalTextures.mask ?? white },
      envMap: { value: textureCache?.globalTextures.envMapBlured },
      flipEnvMap: { value: -1 },
      reflectivity: { value: 1 },
      refractionRatio: { value: 0.98 },
      maxMipLevel: { value: 0 },
      aoMap: { value: null },
      aoMapIntensity: { value: 1 },
      lightMap: { value: null },
      lightMapIntensity: { value: 1 },
      emissiveMap: { value: null },
      normalMap: { value: textureCache?.recto.normalMapRenderTarget?.texture },
      normalScale: { value: { x: 1, y: 1 } },
      roughnessMap: { value: null },
      metalnessMap: { value: null },
      ambientLightColor: {
        value: [0.7278431372549019, 0.7278431372549019, 0.7278431372549019],
        needsUpdate: true,
      },
      emissive: {
        value: new Color(1, 1, 1),
      },
      roughness: { value: 0.1 },
      metalness: { value: 0.01 },
      envMapIntensity: { value: 3 },
      transparency: { value: 1 },
      clearcoat: { value: 0 },
      clearcoatRoughness: { value: 0 },
      clearcoatMap: { value: null },
      sheen: { value: new Color(0, 0, 0) },
      clearcoatNormalScale: { value: { x: 1, y: 1 } },
      clearcoatNormalMap: { value: null },
      clippingPlanes: { value: null, needsUpdate: false },
      parallaxScale: { value: parallaxScale },
      parallaxMinLayers: { value: parallaxMinLayers },
      parallaxMaxLayers: { value: parallaxMaxLayers },
      normalPaper: { value: textureCache?.globalTextures.normalNoise },
      normalScaleFactor: { value: 0.02 },
      vecXNormalScale: { value: 25.0 },
      vecYNormalScale: { value: 50.0 },
      flip: { value: new Vector4(0, 1, 0, 1) },
      labelHeight: { value: dimensions.height },
      labelWidth: { value: dimensions.width },
    };
  }, [textureCache]);

  const bottleInner = useLoader(
    OBJLoader2,
    `${process.env.PUBLIC_URL}/Bordeaux_Inner.obj`
  );
  const bottleOuter = useLoader(
    OBJLoader2,
    `${process.env.PUBLIC_URL}/Bordeaux_Outer.obj`
  );

  function mapGeometry(inner: boolean) {
    const obj: Mesh = inner
      ? (bottleInner.children[0] as Mesh)
      : (bottleOuter.children[0] as Mesh);
    return obj.geometry as BufferGeometry;
  }

  const bottleInnerMaterial = useMemo(() => {
    return new MeshPhongMaterial({
      color: new Color(0.06, 0.16, 0.06),
      envMap: textureCache?.globalTextures.envMapBlured,
      reflectivity: 0.5,
      refractionRatio: 0.95,
      opacity: 0.7,
      transparent: true,
      depthFunc: LessDepth,
    });
  }, [textureCache?.globalTextures.envMapBlured]);

  const bottleOuterMaterial = useMemo(() => {
    return new MeshPhongMaterial({
      color: new Color(0.06, 0.16, 0.06),
      envMap: textureCache?.globalTextures.envMapBlured,
      reflectivity: 0.5,
      refractionRatio: 0.95,
      opacity: 0.7,
      transparent: true,
      depthFunc: LessDepth,
      side: DoubleSide,
    });
  }, [textureCache?.globalTextures.envMapBlured]);

  const Asset = ({
    material,
    renderOrder,
    inner,
    layerName,
  }: {
    material: MeshPhongMaterial | ShaderMaterial;
    renderOrder: number;
    inner?: boolean;
    layerName?: string;
  }) => {
    const newGeometry = useMemo(() => {
      const geometry = inner ? mapGeometry(inner) : mapGeometry(false);
      const newGeometry = BufferGeometryUtils.mergeVertices(geometry);
      BufferGeometryUtils.computeTangents(newGeometry);
      return newGeometry;
    }, []);

    return (
      <>
        <mesh
          geometry={newGeometry}
          position={new Vector3(0, 0, 0)}
          scale={[250, 250, 250]}
          material={material}
          rotation={[-Math.PI / 2, 0, 0]}
          visible={layerName ? layerIsEnabled(layerName, layersView) : true}
          renderOrder={renderOrder}
        />
      </>
    );
  };

  return (
    <>
      <group ref={groupRef}>
        {<Asset material={bottleInnerMaterial} renderOrder={1300} inner />}
        <Asset material={bottleOuterMaterial} renderOrder={1301} />

        {textureCache?.globalTextures.paper && (
          <Asset
            material={
              new ShaderMaterial({
                uniforms: paperUniformsRecto,
                vertexShader: "#define PAPER_BOTTLE" + bottleVertexShader,
                fragmentShader:
                  "#define BOTTLE_SHADER \n" + paperFragmentShader,
                side: DoubleSide,
                transparent: true,
              })
            }
            renderOrder={1100}
            layerName={"paper"}
          />
        )}
        {textureCache?.recto.color && (
          <Asset
            material={
              new ShaderMaterial({
                uniforms: uniforms,
                vertexShader: bottleVertexShader,
                fragmentShader:
                  "#define BOTTLE_SHADER \n" + colorFragmentShader,
                transparent: true,
              })
            }
            renderOrder={1110}
            layerName={"color"}
          />
        )}
        {textureCache?.recto.goldFoil && (
          <Asset
            material={
              new ShaderMaterial({
                uniforms: goldFoilUniform,
                vertexShader: bottleVertexShader,
                fragmentShader:
                  "#define BOTTLE_SHADER \n" + fragmentShaderNormal,
                transparent: true,
              })
            }
            renderOrder={1111}
            layerName={"goldFoil"}
          />
        )}

        {textureCache?.recto.silverFoil && (
          <Asset
            material={
              new ShaderMaterial({
                uniforms: silverFoilUniform,
                vertexShader: bottleVertexShader,
                fragmentShader:
                  "#define BOTTLE_SHADER \n" + fragmentShaderNormal,
                transparent: true,
              })
            }
            renderOrder={1113}
            layerName={"silverFoil"}
          />
        )}
        {textureCache?.recto.varnish && (
          <Asset
            material={
              new ShaderMaterial({
                uniforms: varnishUniformsRecto,
                vertexShader: bottleVertexShader,
                fragmentShader:
                  "#define BOTTLE_SHADER \n" + varnishFragmentShader,
                transparent: true,
              })
            }
            renderOrder={1114}
            layerName={"varnish"}
          />
        )}
      </group>
    </>
  );
}
