import React, { useMemo, useRef } from "react";
import { animated, SpringValue } from "react-spring";
import {
  Color,
  Material,
  PlaneBufferGeometry,
  ShaderMaterial,
  Texture,
  Vector2,
  Vector4,
} from "three";
import { FaceGeometry, layerIsEnabled, LayerView } from "../../Domain";
import { useWhiteTexture } from "../../hooks/WhiteTexture";
import { varnishFragmentShader } from "../Shaders/varnishFragmentShader";
import { varnishVertexShader } from "../Shaders/varnishVertexShader";

export type VarnishMaterialProps = {
  metalness?: number;
  roughness?: number;
  reflectivity?: number;
  envMapIntensity?: number;
  normalScaleFactor?: number;
  vecXNormalScale?: number;
  vecYNormalScale?: number;
  displayedMap?: string;
};

type VarnishProps = {
  normalNoise?: Texture;
  shaderMaterialMode: Boolean;
  gltfMode: Boolean;
  geometry: PlaneBufferGeometry;
  faceGeometry: FaceGeometry;
  normalMap: Texture | undefined;
  envMap?: Texture;
  varnish: Texture | undefined;
  animatedOpacity: SpringValue<number>;
  faceName: string;
  parallaxScale: number;
  parallaxMinLayers: number;
  parallaxMaxLayers: number;
  layersView: LayerView[];
  faceUVs: { repeat: Vector2; offset: Vector2 };
  verso?: boolean;
  mask?: Texture;
};

const Varnish = ({
  normalNoise,
  shaderMaterialMode,
  gltfMode,
  geometry,
  faceGeometry,
  normalMap,
  envMap,
  parallaxScale,
  parallaxMinLayers,
  parallaxMaxLayers,
  layersView,
  varnish,
  animatedOpacity,
  faceName,
  faceUVs,
  metalness,
  roughness,
  reflectivity,
  envMapIntensity,
  normalScaleFactor,
  vecXNormalScale,
  vecYNormalScale,
  displayedMap,
  verso,
  mask,
}: VarnishProps & VarnishMaterialProps) => {
  const materialRef = useRef<ShaderMaterial>();

  if (materialRef.current) {
    materialRef.current.uniformsNeedUpdate = true;
  }

  const white = useWhiteTexture();

  const varnishUniformsRecto = useMemo(() => {
    return {
      diffuse: {
        value: new Color(1, 1, 1),
      },
      opacity: { value: animatedOpacity },
      map: { value: varnish },
      uvTransform: {
        value: [
          faceUVs.repeat.x,
          -0,
          0,
          0,
          faceUVs.repeat.y,
          0,
          faceUVs.offset.x,
          faceUVs.offset.y,
          1,
        ],
      },
      uv2Transform: { value: [1, 0, 0, 0, 1, 0, 0, 0, 1] },
      alphaMap: { value: mask ?? white },
      envMap: { value: envMap },
      flipEnvMap: { value: -1 },
      reflectivity: { value: reflectivity ?? 1 },
      refractionRatio: { value: 0.98 },
      maxMipLevel: { value: 0 },
      aoMap: { value: null },
      aoMapIntensity: { value: 1 },
      lightMap: { value: null },
      lightMapIntensity: { value: 1 },
      emissiveMap: { value: null },
      normalMap: { value: normalMap },
      normalScale: { value: { x: 1, y: 1 } },
      roughnessMap: { value: null },
      metalnessMap: { value: null },
      ambientLightColor: {
        value: [0.7278431372549019, 0.7278431372549019, 0.7278431372549019],
        needsUpdate: true,
      },
      emissive: {
        value: new Color(1, 1, 1),
      },
      roughness: { value: roughness ?? 0.1 },
      metalness: { value: metalness ?? 0.01 },
      envMapIntensity: { value: envMapIntensity ?? 3 },
      transparency: { value: 1 },
      clearcoat: { value: 0 },
      clearcoatRoughness: { value: 0 },
      clearcoatMap: { value: null },
      sheen: { value: new Color(0, 0, 0) },
      clearcoatNormalScale: { value: { x: 1, y: 1 } },
      clearcoatNormalMap: { value: null },
      clippingPlanes: { value: null, needsUpdate: false },
      parallaxScale: { value: parallaxScale },
      parallaxMinLayers: { value: parallaxMinLayers },
      parallaxMaxLayers: { value: parallaxMaxLayers },
      normalPaper: { value: normalNoise },
      normalScaleFactor: { value: normalScaleFactor ?? 0.02 },
      vecXNormalScale: { value: vecXNormalScale ?? 25.0 },
      vecYNormalScale: { value: vecYNormalScale ?? 50.0 },
      displayedMap: { displayedMap },
      flip: { value: new Vector4(0, 1, 0, 1) },
    };
  }, []);

  const varnishUniformsVerso = {
    ...varnishUniformsRecto,
    flip: { value: new Vector4(1, -1, 0, 1) },
  };

  return (
    <mesh
      name={faceName}
      geometry={geometry}
      position={[
        faceGeometry.width / 2,
        -faceGeometry.height / 2,
        verso ? 0 : 1.1,
      ]}
      visible={layerIsEnabled("varnish", layersView)}
      rotation={verso ? [0, Math.PI, 0] : [0, 0, 0]}
    >
      {shaderMaterialMode ? (
        <animated.shaderMaterial
          ref={materialRef}
          onUpdate={(self: Material) => (self.needsUpdate = true)}
          needsUpdate={true}
          uniformsNeedUpdate={true}
          attach="material"
          uniforms={verso ? varnishUniformsVerso : varnishUniformsRecto}
          vertexShader={varnishVertexShader}
          fragmentShader={varnishFragmentShader}
          transparent
        />
      ) : gltfMode ? (
        <animated.meshStandardMaterial
          onUpdate={(self: Material) => (self.needsUpdate = true)}
          attach="material"
          map={varnish}
          transparent
          opacity={0.2}
          metalness={1}
          roughness={0}
          normalMap={normalMap}
          normalScale={new Vector2(1, 1)}
        />
      ) : (
        <animated.meshPhysicalMaterial
          onUpdate={(self: Material) => (self.needsUpdate = true)}
          attach="material"
          map={varnish}
          transparent
          transparency={0.9}
          roughness={0.25}
          reflectivity={0.4}
          refractionRatio={0.98}
          emissive={0xffffff}
          color={0x000000}
          normalMap={normalMap}
          normalScale={new Vector2(1, 1)}
        />
      )}
    </mesh>
  );
};
export default Varnish;
