import {
  Mesh,
  NoBlending,
  PlaneBufferGeometry,
  RGBAFormat,
  Scene,
  ShaderMaterial,
  Texture,
  WebGLRenderTarget,
} from "three";

const rtVertexShader = `
      varying vec2 vUv;

			void main() {

				vUv = uv;
				gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );

      }`;

export function initRenderTarget(
  textures: Texture[],
  fragmentShader: string
): [WebGLRenderTarget | undefined, Scene | undefined] {
  const image = textures[0]?.image as HTMLImageElement | undefined;
  if (!image) {
    return [undefined, undefined];
  }

  const rtTexture = new WebGLRenderTarget(image.width, image.height, {
    format: RGBAFormat,
  });

  const plane = new PlaneBufferGeometry(image.width, image.height);

  const uniforms = textures
    .map((texture, index) => ({
      [`texture${index}`]: { value: texture },
      vSizeTexture: {
        value: [image.width, image.height, 1 / image.width, 1 / image.height],
      },
    }))
    .reduce((acc, newUniform) => ({ ...acc, ...newUniform }), {});

  const material = new ShaderMaterial({
    vertexShader: rtVertexShader,
    fragmentShader,
    uniforms,
    blending: NoBlending,
  });
  const quad = new Mesh(plane, material);
  const scene = new Scene();
  scene.add(quad);

  return [rtTexture, scene];
}
