import { ProductDesign } from "../Product";

export type PdfConverter = (pdf: Uint8Array) => Promise<ProductDesign>;

export class ConvertPdfService {
  faceExtractor: PdfConverter;
  constructor(faceExtractor: PdfConverter) {
    this.faceExtractor = faceExtractor;
  }

  async call(file: File): Promise<ProductDesign> {
    return new Promise<ProductDesign>((resolve) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = async () => {
        const pdfFileContent = new Uint8Array(reader.result as ArrayBuffer);
        const imagePackaging = await this.faceExtractor(pdfFileContent);
        resolve(imagePackaging);
      };
    });
  }
}
