export type Annotations = {
  eventId?: number;
  x: number;
  y: number;
  z: number;
  face: string;
  size: number;
  isSelected?: boolean;
  isVerso: boolean;
};

export const DEFAULT_ANNOTATION_SIZE = 1;
